import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import useConfirm from "../../../hooks/useConfirm";

function ManageClients() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [selectedTab, setSelectedTab] = useState("Orion Allied");

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    fetchComplianceFiles(selectedTab);
  };

  /*
  const [categories, setCategories] = useState([]);
  const fetchDocumentCategories = async () => {
    try {
      const param = {
        user_id: user.id,
      };
      const apiData = await api.postMethod(param, "client/get-client-types");

      setCategories(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentCategories();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  */

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);
  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        /*tab: tab,*/
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "client/get-clients");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles(selectedTab);
  }, [selectedTab]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    type: null,
    state_id: null,
    city_id: null,
    status: null,
    contact_person_1_title: null,
    contact_person_1_name: null,
    contact_person_1_phone: null,
    contact_person_1_email: null,
    contact_person_2_title: null,
    contact_person_2_name: null,
    contact_person_2_phone: null,
    contact_person_2_email: null,
  });

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        /*type: null,*/
        state_id: null,
        city_id: null,
        status: null,
        contact_person_1_title: null,
        contact_person_1_name: null,
        contact_person_1_phone: null,
        contact_person_1_email: null,
        contact_person_2_title: null,
        contact_person_2_name: null,
        contact_person_2_phone: null,
        contact_person_2_email: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      title: record.title,
      /*type: record.type,*/
      state_id: record.state_id,
      city_id: record.city_id,
      status: record.status,

      contact_person_1_title: record.contact_person_1_title,
      contact_person_1_name: record.contact_person_1_name,
      contact_person_1_phone: record.contact_person_1_phone,
      contact_person_1_email: record.contact_person_1_email,
      contact_person_2_title: record.contact_person_2_title,
      contact_person_2_name: record.contact_person_2_name,
      contact_person_2_phone: record.contact_person_2_phone,
      contact_person_2_email: record.contact_person_2_email,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "client/delete-client");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        /*fetchDocumentCategories();*/
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    /*type: Yup.string().required("Type is Required"),*/
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-client");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        /*fetchDocumentCategories();*/
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "client/update-client-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const [selectedClient, setSelectedClient] = useState(null);
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = (client) => {
    setSelectedClient(client);
    setShow(true);
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Clients", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}

              <PageTitle title="Clients" breadcrumbItems={breadcrumbItems} />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    {/* 
                    <div className="card-header border-bottom">
                      <div className="row row-cols-2">
                        {categories.length > 0 &&
                          categories.map((etype) => (
                            <div className="col my-1" key={etype.type}>
                              <button
                                type="button"
                                className={
                                  "btn  w-100 " +
                                  (etype.type === null && selectedTab === etype.type
                                    ? "btn-primary"
                                    : selectedTab === etype.type
                                    ? "btn-primary"
                                    : "btn-light")
                                }
                                onClick={() => handleTabSelect(etype.type)}
                              >
                                {etype.type} ({etype.count})
                              </button>
                            </div>
                          ))}
                      </div>
                    </div>
                    */}

                    <div className="card-body">
                      <div className="row justify-content-between mb-2">
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="keyword" className="form-label">
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>

                                <div className="mb-2">
                                  <label htmlFor="status" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-select"
                                    id="cstatus"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterInputChange}
                                  >
                                    <option value={"all"}>Please Select</option>
                                    <option value={1}>Active</option>
                                    <option value={0}>In-Active</option>
                                  </select>
                                </div>

                                <div className="text-end mt-1">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <button
                              type="button"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                              onClick={toggleUploadModal}
                            >
                              <i className="fa fa-plus mr-1"></i> Add New
                            </button>
                          </div>
                        </div>
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th>Title</th>
                              <th>Location</th>
                              <th>Created On</th>
                              <th>Status</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="5">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="5">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(complianceFiles).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>{value.title}</td>
                                        <td>
                                          {value.state_name !== null && value.city_name !== null
                                            ? value.state_name + ", " + value.city_name
                                            : value.state_name !== null
                                            ? value.state_name
                                            : value.city_name !== null
                                            ? value.state_name
                                            : ""}
                                        </td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <span className="badge badge-soft-success">Active</span>
                                          )}
                                          {value.status == 0 && (
                                            <span className="badge badge-soft-warning">
                                              In-Active
                                            </span>
                                          )}
                                        </td>
                                        <td>
                                          {value.status == 0 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="In-Active"
                                                onClick={() => updateStatus(value.id, 1)}
                                              >
                                                <i className="mdi mdi-close-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}
                                          {value.status == 1 && (
                                            <>
                                              <button
                                                type="button"
                                                className="btn btn-default action-icon"
                                                data-tooltip-id="mt-1"
                                                data-tooltip-content="Active"
                                                onClick={() => updateStatus(value.id, 0)}
                                              >
                                                <i className="mdi mdi-check-box-outline" />
                                              </button>
                                              <Tooltip id="mt-1"></Tooltip>
                                            </>
                                          )}

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="Edit Client"
                                            onClick={() => handleEdit(value)}
                                          >
                                            <i className="mdi mdi-pencil" />
                                          </button>
                                          <Tooltip id="mt-3"></Tooltip>

                                          <button
                                            button="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-3"
                                            data-tooltip-content="View Client"
                                            onClick={() => handleShow(value)}
                                          >
                                            <i className="mdi mdi-eye" />
                                          </button>
                                          <Tooltip id="mt-4"></Tooltip>

                                          <button
                                            type="button"
                                            className="btn btn-default action-icon"
                                            data-tooltip-id="mt-2"
                                            data-tooltip-content="Remove Client"
                                            onClick={() => handleDelete(value)}
                                          >
                                            <i className="mdi mdi-delete" />
                                          </button>
                                          <Tooltip id="mt-2"></Tooltip>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      {selectedClient && (
        <Modal show={show} onHide={handleClose} centered backdrop="static" size="md">
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Client Details</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Title</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.title}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>State</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.state_name}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>City</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.city_name}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h5>Contact Person 1</h5>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Title</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_1_title || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Name</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_1_name || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Mobile</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_1_mobile || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Email</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_1_email || "-"}</p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h5>Contact Person 2</h5>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Title</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_2_title || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Name</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_2_name || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Mobile</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_2_mobile || "-"}</p>
              </div>
            </div>

            <div className="row mb-2">
              <div className="col-md-6">
                <p className="mb-0">
                  <b>Email</b>
                </p>
              </div>
              <div className="col-md-6">
                <p className="text-muted mb-0">{selectedClient.contact_person_2_email || "-"}</p>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button
              type="button"
              className="btn btn-secondary waves-effect"
              onClick={() => setShow(false)}
            >
              Close
            </button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Client</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="title">
                  Title *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  defaultValue={formData.title !== null ? formData.title : ""}
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>

              {/* 
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="type">
                  Type *
                </label>
                <select
                  className="form-control"
                  id="type"
                  name="type"
                  onChange={handleChange}
                  value={formData.type && formData.type}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  <option value="Orion Allied" key="Orion Allied">
                    Orion Allied
                  </option>
                  <option value="Orion Workforce" key="Orion Workforce">
                    Orion Workforce
                  </option>
                </select>
                {errors.type && <span className="error">{errors.type}</span>}
              </div>
              */}

              <div className="mb-2 col-md-6">
                <label className="form-label" htmlFor="state_id">
                  State *
                </label>
                <select
                  id="state_id"
                  className="form-control"
                  name="state_id"
                  value={formData.state_id !== null ? formData.state_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state_id && <span className="error">{errors.state_id}</span>}
              </div>

              <div className="mb-2 col-md-6">
                <label className="form-label" htmlFor="city_id">
                  City *
                </label>
                <select
                  id="city_id"
                  className="form-control"
                  name="city_id"
                  value={formData.city_id !== null ? formData.city_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
                {errors.city_id && <span className="error">{errors.city_id}</span>}
              </div>

              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="fstatus">
                  Status
                </label>
                <select
                  className="form-control"
                  id="fstatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : "1"}
                >
                  <option key="1" value="1">
                    Active
                  </option>
                  <option key="0" value="0">
                    In-Active
                  </option>
                </select>
                {errors.cat_id && <span className="error">{errors.cat_id}</span>}
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h5>Contact Person 1</h5>
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_1_title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_1_title"
                  name="contact_person_1_title"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_1_title !== null ? formData.contact_person_1_title : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_1_name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_1_name"
                  name="contact_person_1_name"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_1_name !== null ? formData.contact_person_1_name : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_1_phone">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_1_phone"
                  name="contact_person_1_phone"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_1_phone !== null ? formData.contact_person_1_phone : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_1_email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="contact_person_1_email"
                  name="contact_person_1_email"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_1_email !== null ? formData.contact_person_1_email : ""
                  }
                />
              </div>
            </div>

            <div className="row">
              <div className="col-md-12">
                <h5>Contact Person 1</h5>
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_2_title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_2_title"
                  name="contact_person_2_title"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_2_title !== null ? formData.contact_person_2_title : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_2_name">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_2_name"
                  name="contact_person_2_name"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_2_name !== null ? formData.contact_person_2_name : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_2_phone">
                  Phone
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="contact_person_2_phone"
                  name="contact_person_2_phone"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_2_phone !== null ? formData.contact_person_2_phone : ""
                  }
                />
              </div>
              <div className="col-md-6 mb-1">
                <label className="form-label" htmlFor="contact_person_2_email">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="contact_person_2_email"
                  name="contact_person_2_email"
                  onChange={handleChange}
                  defaultValue={
                    formData.contact_person_2_email !== null ? formData.contact_person_2_email : ""
                  }
                />
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default ManageClients;
