import React from 'react'
import Layout from '../../Layout/Index'
import PageTitle from '../../Layout/PageTitle';
const breadcrumbItems = [
    { text: 'Home', link: './dashboard.html' },
    { text: 'Compliance Files', link: './compliance-files.html' },
    { text: 'Facility Compliance Lists', link: null },
];


const FacilityComplianceLists = () => {
    return (
        <Layout>
            <PageTitle title="Facility Compliance Lists" breadcrumbItems={breadcrumbItems} />

            {/* end page title */}
            <div className="row">
                <div className="col-12">
                    <div className="card custom-box-shadow">
                        <div className="card-body">
                            <div className="row justify-content-between">
                                <div className="col-auto">
                                    <form className="d-flex flex-wrap align-items-center">
                                        <div className="me-2">
                                            <label htmlFor="inputPassword2" className="sr-only">Search</label>
                                            <input type="search" className="form-control" id="inputPassword2" placeholder="Search by keyword..." />
                                        </div>
                                        <label htmlFor="status-select" className="me-2">Status</label>
                                        <div className="me-sm-2">
                                            <select className="form-select my-1 my-lg-0" id="status-select">
                                                <option selected>Choose...</option>
                                                <option value={1}>Active</option>
                                                <option value={2}>In-Active</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                                <div className="col-auto">
                                    <div className="text-lg-end my-1 my-lg-0">
                                        <a href="add-facility-compliance.html" className="btn btn-primary waves-effect waves-light mb-2">
                                            <i className="fa fa-plus mr-1" /> Add New List
                                        </a>
                                        <a href="assign-facility-compliance.html" className="btn btn-info waves-effect waves-light mb-2">
                                            <i className="fa fa-cog mr-1" /> Assign List
                                        </a>
                                    </div>
                                </div>
                                {/* end col*/}
                            </div>
                            <div className="table-responsive">
                                <table className="table table-centered table-nowrap mb-0">
                                    <thead className="table-light">
                                        <tr>
                                            <th>Facility Title</th>
                                            <th>Facility Option</th>
                                            <th>Documents</th>
                                            <th>Status</th>
                                            <th>Creation Date</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>Rehoboth McKinley Christian Health Care Services</td>
                                            <td>Onboarding</td>
                                            <td>
                                                <button className="btn btn-secondary btn-xs">
                                                    <i className="fa fa-upload" />
                                                </button>
                                            </td>
                                            <td><span className="badge badge-soft-success">Active</span></td>
                                            <td>
                                                01 Jan 2024
                                                <small className="text-muted">10:29 PM</small>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-content-copy" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-pencil" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-delete" /></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Midland Memorial Hospital</td>
                                            <td>Onboarding</td>
                                            <td>
                                                <button className="btn btn-secondary btn-xs">
                                                    <i className="fa fa-upload" />
                                                </button>
                                            </td>
                                            <td><span className="badge badge-soft-success">Active</span></td>
                                            <td>
                                                20 Dec 2023
                                                <small className="text-muted">10:29 PM</small>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-content-copy" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-pencil" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-delete" /></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Cobre Valley Regional Medical Center</td>
                                            <td>Onboarding</td>
                                            <td>
                                                <button className="btn btn-secondary btn-xs">
                                                    <i className="fa fa-upload" />
                                                </button>
                                            </td>
                                            <td><span className="badge badge-soft-warning">In-Active</span></td>
                                            <td>
                                                01 Jan 2024
                                                <small className="text-muted">10:29 PM</small>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-content-copy" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-pencil" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-delete" /></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Comanche County Memorial</td>
                                            <td>Onboarding</td>
                                            <td>
                                                <button className="btn btn-secondary btn-xs">
                                                    <i className="fa fa-upload" />
                                                </button>
                                            </td>
                                            <td><span className="badge badge-soft-success">Active</span></td>
                                            <td>
                                                20 Dec 2023
                                                <small className="text-muted">10:29 PM</small>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-content-copy" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-pencil" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-delete" /></a>
                                            </td>
                                        </tr>
                                        <tr>
                                            <td>Baptist Health</td>
                                            <td>Onboarding</td>
                                            <td>
                                                <button className="btn btn-secondary btn-xs">
                                                    <i className="fa fa-upload" />
                                                </button>
                                            </td>
                                            <td><span className="badge badge-soft-warning">In-Active</span></td>
                                            <td>
                                                01 Jan 2024
                                                <small className="text-muted">10:29 PM</small>
                                            </td>
                                            <td>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-content-copy" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-pencil" /></a>
                                                <a href="javascript:void(0);" className="action-icon">
                                                    <i className="mdi mdi-delete" /></a>
                                            </td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                            <ul className="pagination pagination-rounded justify-content-end my-2">
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);" aria-label="Previous">
                                        <span aria-hidden="true">«</span>
                                        <span className="sr-only">Previous</span>
                                    </a>
                                </li>
                                <li className="page-item active">
                                    <a className="page-link" href="javascript: void(0);">1</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);">2</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);">3</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);">4</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);">5</a>
                                </li>
                                <li className="page-item">
                                    <a className="page-link" href="javascript: void(0);" aria-label="Next">
                                        <span aria-hidden="true">»</span>
                                        <span className="sr-only">Next</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                        {/* end card-body*/}
                    </div>
                    {/* end card*/}
                </div>
                {/* end col */}
            </div>
        </Layout>
    )
}

export default FacilityComplianceLists