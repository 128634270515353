import React, { useState, useEffect } from "react";
import { Link, NavLink, useLocation, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Tooltip } from "react-tooltip";
import Modal from "react-bootstrap/Modal";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

import * as Yup from "yup";

function Jobs() {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const navigate = useNavigate();
  const location = useLocation();
  const isBoostedJobs = location.pathname.includes("boosted-jobs");

  const [isLoading, setIsLoading] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const toggleModal = () => {
    setModalOpen(!modalOpen);
  };

  const [showJobFilterDropdown, setJobFilterDropdown] = useState(false);
  const handleToggleJobFilterDropDown = () => {
    setJobFilterDropdown(!showJobFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    job_title: "",
    state_id: "",
    date_posted: "",
    starred_job: false,
    order_by: "default",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      job_title: "",
      state_id: "",
      date_posted: "",
      starred_job: false,
      order_by: "default",
    };
    setFilters(defaultFilters);
    setJobFilterDropdown(false);
  };

  useEffect(() => {
    if (showJobFilterDropdown === false) {
      fetchJobData(filters);
    }
  }, [showJobFilterDropdown]);

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleSubmit = (e) => {
    e.preventDefault();
    fetchJobData(filters);
    setJobFilterDropdown(false);
  };

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [boostModal, setBoostModal] = useState(false);
  const toggleBoostModal = (value) => {
    setBoostModal(!boostModal);
  };

  // State for filter values
  const [formData, setformData] = useState({
    user_id: user.id,
    job_id: "",
    amount: "",
    startDate: "",
    endDate: "",
  });

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      job_id: record.id,
      amount: record.boosted_amount,
      startDate: record.boosted_start_date,
      endDate: record.boosted_end_date,
    });

    setBoostModal(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/delete-job");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchJobData();
      }
    }
  };

  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobData = async () => {
    try {
      setLoading(true);
      const param = {
        user_id: user.id,
        token: user.token,
      };

      const queryParams = { ...param, ...filters };

      let apiData;
      if (isBoostedJobs) apiData = await api.postMethod(queryParams, "client/get-boosted-jobs");
      else apiData = await api.postMethod(queryParams, "client/get-jobs");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setJobs(apiData.data);
      setLoading(false);
      // Check if data array is empty
      setNoRecordsFound(false);
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const toggleStarredJob = async (job_id, status) => {
    setIsLoading(true);
    const param = {
      user_id: user.id,
      token: user.token,
      job_id: job_id,
      status: status,
    };
    const result = await api.postMethod(param, "client/toggle-starred-job");
    setIsLoading(false);
    if (result.status) {
      toast.success(result.message);
      fetchJobData();
    }
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: isBoostedJobs ? "Boosted Jobs" : "Jobs", link: null },
  ];

  const [errors, setErrors] = useState({});
  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .required("Amount is required")
      .positive("Amount must be a positive number"),
    startDate: Yup.date().required("Start Date is required").nullable(),
    endDate: Yup.date()
      .required("End Date is required")
      .nullable()
      .min(Yup.ref("startDate"), "End Date cannot be before Start Date"),
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });
    validateFields(name, value);
  };

  const handleSubmitBoost = async (e) => {
    e.preventDefault();
    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postMethod(formData, "client/update-boosted-job");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchJobData();
      }

      // Close the modal after form submission
      setBoostModal(false);
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "client/update-job-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchJobData();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  /* Select All checkbox functions start */

  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = jobs.map((record) => record.id);

      setSelectedJobIds(allUserIds);
    } else {
      setSelectedJobIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedJobIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedJobIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          job_ids: selectedJobIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "client/job-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedJobIds([]);
          setBulkAction("");
          fetchJobData();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedJobIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedJobIds.includes(record.id)));
          setSelectedJobIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedJobIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedJobIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedJobIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle
                title={isBoostedJobs ? "Boosted Jobs" : "Jobs"}
                breadcrumbItems={breadcrumbItems}
              />

              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between">
                        <div className="col-md-6">
                          <div className="">
                            <select
                              className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                              value={bulkAction}
                              onChange={handleBulkActionChange}
                            >
                              <option value="">Bulk Actions</option>
                              <option value="delete">Delete</option>
                              <option value="change-status-active">Change Status to Active</option>
                              <option value="change-status-inactive">
                                Change Status to In-Active
                              </option>
                            </select>
                            <button onClick={handleBulkActionSubmit} className="btn btn-info">
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleJobFilterDropDown}
                            >
                              Filter and Search Jobs
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showJobFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="job_title" className="form-label">
                                    Job Title
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="job_title"
                                    name="job_title"
                                    value={filters.job_title}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="state_id" className="form-label">
                                    Location
                                  </label>
                                  <select
                                    className="form-control"
                                    id="state_id"
                                    name="state_id"
                                    value={filters.state_id}
                                    onChange={handleInputChange}
                                  >
                                    <option value="" key="">
                                      Please Select
                                    </option>
                                    {/* Render the states */}
                                    {states.map((state) => (
                                      <option key={state.id} value={state.id}>
                                        {state.name}
                                      </option>
                                    ))}
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="date_posted" className="form-label">
                                    Date Posted
                                  </label>
                                  <input
                                    type="date"
                                    className="form-control"
                                    id="date_posted"
                                    name="date_posted"
                                    value={filters.date_posted}
                                    onChange={handleInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="order_by" className="form-label">
                                    Order By
                                  </label>
                                  <select
                                    className="form-control"
                                    id="order_by"
                                    name="order_by"
                                    value={filters.order_by}
                                    onChange={handleInputChange}
                                  >
                                    <option value="default" key="default">
                                      Default
                                    </option>
                                    <option value="highest" key="highest">
                                      Highest Pay First
                                    </option>
                                    <option value="lowest" key="lowest">
                                      Lowest Pay First
                                    </option>
                                  </select>
                                </div>
                                <div className="mb-2">
                                  <div className="form-check">
                                    <input
                                      type="checkbox"
                                      className="form-check-input"
                                      id="starred_job"
                                      name="starred_job"
                                      checked={filters.starred_job}
                                      onChange={handleInputChange}
                                    />
                                    <label className="form-check-label" htmlFor="starred_job">
                                      <i className="fa fa-star" /> Starred
                                    </label>
                                  </div>
                                </div>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="text-lg-end my-1 my-lg-0">
                            <Link
                              to="/client/job-post-step1"
                              className="btn btn-primary waves-effect waves-light mb-2 me-1"
                            >
                              <i className="fa fa-plus mr-1" /> Post a Job
                            </Link>

                            {/* !isBoostedJobs ? (
                              <Link
                                to="/client/boosted-jobs"
                                className="btn btn-info waves-effect waves-light mb-2"
                              >
                                <i className="mr-1" data-feather="zap" /> Boosted Jobs
                              </Link>
                            ) : (
                              <Link
                                to="/client/jobs"
                                className="btn btn-info waves-effect waves-light mb-2"
                              >
                                <i className="mr-1" data-feather="zap" /> All Jobs
                              </Link>
                            ) */}
                          </div>
                        </div>
                        {/* end col*/}
                      </div>

                      <div className="table-responsive">
                        <table className="table table-centered mb-0">
                          {/* table-nowrap */}
                          <thead className="table-light">
                            <tr>
                              <th className="form-checks">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAllChange}
                                  checked={selectedJobIds.length === jobs.length && jobs.length > 0}
                                  className="form-check-input"
                                />
                              </th>
                              <th>&nbsp;</th>
                              <th width="10%">Job Title</th>
                              <th>Applicants</th>
                              <th>Specialty</th>
                              <th>Status</th>
                              {/* 
                              <th>Boost</th>
*/}
                              <th>Date Posted</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="8">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="8">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {jobs &&
                                      Object.entries(jobs).map(([key, value]) => (
                                        <tr key={value.id}>
                                          <td className="form-checks">
                                            <input
                                              type="checkbox"
                                              value={value.id}
                                              name="job_ids"
                                              onChange={handleCheckboxChange}
                                              checked={selectedJobIds.includes(value.id)}
                                              className="form-check-input"
                                            />
                                          </td>
                                          <td>
                                            <span
                                              className={
                                                value.is_starred == 1
                                                  ? "star-toggle fas fa-star text-warning"
                                                  : "star-toggle far fa-star"
                                              }
                                              onClick={() =>
                                                toggleStarredJob(value.id, value.is_starred)
                                              }
                                            ></span>
                                          </td>
                                          <td>
                                            <Link
                                              to={`/client/job/` + value.unique_id}
                                              className="job-title"
                                              data-tooltip-id={`mt-` + value.unique_id}
                                              data-tooltip-content={value.title}
                                            >
                                              {value.title}
                                            </Link>
                                            <Tooltip id={`mt-` + value.unique_id}></Tooltip>

                                            <p className="mb-0 text-muted">
                                              <small>(#{value.unique_id})</small>
                                            </p>
                                            {(value.state_name !== null ||
                                              value.city_name !== null) && (
                                              <p className="mb-0 ">
                                                <small>
                                                  <i className="fa fa-map-marker-alt me-1"></i>
                                                  {value.state_name !== null &&
                                                  value.city_name !== null
                                                    ? value.state_name + ", " + value.city_name
                                                    : value.state_name !== null
                                                    ? value.state_name
                                                    : value.city_name !== null
                                                    ? value.state_name
                                                    : ""}
                                                </small>
                                              </p>
                                            )}
                                          </td>
                                          <td>
                                            <div className="btn-group mb-2">
                                              <button
                                                onClick={() =>
                                                  navigate(`/admin/job/` + value.unique_id, {
                                                    state: { tab: "job_applications" },
                                                  })
                                                }
                                                type="button"
                                                className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                              >
                                                {value.applied_jobs}
                                                <br />
                                                Applied
                                              </button>

                                              <button
                                                onClick={() =>
                                                  navigate(`/admin/job/` + value.unique_id, {
                                                    state: { tab: "job_applications" },
                                                  })
                                                }
                                                type="button"
                                                className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                              >
                                                {value.shortlisted_jobs}
                                                <br />
                                                Shortlisted
                                              </button>

                                              <button
                                                onClick={() =>
                                                  navigate(`/admin/job/` + value.unique_id, {
                                                    state: { tab: "job_applications" },
                                                  })
                                                }
                                                type="button"
                                                className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                              >
                                                {value.submitted_jobs}
                                                <br />
                                                Submitted
                                              </button>

                                              {/* 
                                      <button
                                        onClick={() =>
                                          navigate(`/admin/job/` + value.unique_id, {
                                            state: { tab: "job_applications" },
                                          })
                                        }
                                        type="button"
                                        className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                      >
                                        {value.interview_jobs}
                                        <br />
                                        Interview
                                      </button>
                                      */}
                                              <button
                                                onClick={() =>
                                                  navigate(`/admin/job/` + value.unique_id, {
                                                    state: { tab: "job_applications" },
                                                  })
                                                }
                                                type="button"
                                                className="btn btn-light fw-bold px-1 py-1 border-end font-13"
                                              >
                                                {value.offered_jobs}
                                                <br />
                                                Offered
                                              </button>
                                              <button
                                                onClick={() =>
                                                  navigate(`/admin/job/` + value.unique_id, {
                                                    state: { tab: "job_applications" },
                                                  })
                                                }
                                                type="button"
                                                className="btn btn-light fw-bold px-1 py-1 font-13"
                                              >
                                                {value.hired_jobs}
                                                <br />
                                                &nbsp;&nbsp;Hired&nbsp;&nbsp;
                                              </button>
                                            </div>
                                          </td>
                                          <td>
                                            {value.specialty !== null ? value.specialty : "-"}
                                          </td>
                                          <td>
                                            <h5>
                                              {value.status == 1 && (
                                                <label className="badge badge-soft-success">
                                                  Active
                                                </label>
                                              )}
                                              {value.status == 2 && (
                                                <label className="badge badge-soft-danger">
                                                  In-Active
                                                </label>
                                              )}
                                              {value.status == 0 && (
                                                <label className="badge badge-soft-secondary">
                                                  Draft
                                                </label>
                                              )}
                                              {value.status == 6 && (
                                                <label className="badge badge-soft-danger">
                                                  Expired
                                                </label>
                                              )}
                                            </h5>
                                          </td>

                                          {/* 
                                          <td>
                                            <button
                                              type="button"
                                              onClick={() => handleEdit(value)}
                                              className={
                                                value.is_boosted == 1
                                                  ? "btn btn-primary btn-sm waves-effect waves-light mb-2 mr-2"
                                                  : "btn btn-secondary btn-sm waves-effect waves-light mb-2 mr-2"
                                              }
                                            >
                                              Boost
                                            </button>
                                          </td>
                                          */}

                                          <td>
                                            {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                            <small className="text-muted">
                                              {formatDate(value.created_at, "hh:mm A")}
                                            </small>
                                          </td>
                                          <td>
                                            <Dropdown className="">
                                              <Dropdown.Toggle
                                                variant="default"
                                                id={`dropdown-basic-` + value.job_id}
                                                key={`dropdown-basic-` + value.job_id}
                                                className="p-0"
                                              >
                                                <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                              </Dropdown.Toggle>

                                              <Dropdown.Menu>
                                                {value.status == 2 && (
                                                  <Dropdown.Item
                                                    onClick={() => updateStatus(value.id, 1)}
                                                  >
                                                    Mark as Active
                                                  </Dropdown.Item>
                                                )}
                                                {value.status == 1 && (
                                                  <Dropdown.Item
                                                    onClick={() => updateStatus(value.id, 2)}
                                                  >
                                                    Mark as In-Active
                                                  </Dropdown.Item>
                                                )}

                                                <Dropdown.Item
                                                  as={NavLink}
                                                  to={`/client/job-post-step1/` + value.unique_id}
                                                >
                                                  Edit
                                                </Dropdown.Item>

                                                <Dropdown.Item onClick={() => handleDelete(value)}>
                                                  Delete
                                                </Dropdown.Item>
                                              </Dropdown.Menu>
                                            </Dropdown>
                                          </td>
                                        </tr>
                                      ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>

              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <Modal show={modalOpen} onHide={toggleModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Message</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-1">
                  <textarea className="form-control" id="jobMsgTxt" rows={5}></textarea>
                </div>
              </div>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <button type="button" className="btn btn-secondary" onClick={toggleModal}>
            Close
          </button>
          <button type="button" className="btn btn-primary" onClick={toggleModal}>
            Submit
          </button>
        </Modal.Footer>
      </Modal>

      <Modal show={boostModal} onHide={toggleBoostModal} centered size="md">
        <Modal.Header closeButton>
          <Modal.Title className="my-0">Boost Job</Modal.Title>
        </Modal.Header>
        <form onSubmit={handleSubmitBoost}>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12">
                <div className="mb-2">
                  <label htmlFor="amount" className="form-label">
                    Amount
                  </label>
                  <div className="input-group">
                    <div className="input-group-prepend">
                      <span className="input-group-text" id="inputGroupPrepend">
                        $
                      </span>
                    </div>
                    <input
                      type="number"
                      className="form-control"
                      id="amount"
                      name="amount"
                      onChange={handleChange}
                      value={formData.amount}
                    />
                  </div>
                  {errors.amount && <span className="error">{errors.amount}</span>}
                </div>
                <div className="mb-2">
                  <label htmlFor="startDate" className="form-label">
                    Start Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="startDate"
                    name="startDate"
                    onChange={handleChange}
                    value={formData.startDate}
                  />
                  {errors.startDate && <span className="error">{errors.startDate}</span>}
                </div>
                <div className="mb-2">
                  <label htmlFor="endDate" className="form-label">
                    End Date
                  </label>
                  <input
                    type="date"
                    className="form-control"
                    id="endDate"
                    name="endDate"
                    onChange={handleChange}
                    value={formData.endDate}
                  />
                  {errors.endDate && <span className="error">{errors.endDate}</span>}
                </div>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleBoostModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Jobs;
