import React, { useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";

function JobPostStep4() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { jobID } = useParams();
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /*const [jobID, setJobID] = useState("");*/

  const fetchDraftJobs = async () => {
    try {
      setIsLoading(true);
      const param = {
        step: "step4",
        user_id: user.id,
        token: user.token,
        job_id: localStorage.getItem("job_id"),
        jobID: jobID,
      };
      const apiData = await api.postMethod(param, "client/get-draft-job");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);
      if (apiData.status) {
        setformData(apiData.data);
        setIsLoading(false);
      } else {
        setIsLoading(false);
        navigate("/client/job-post-step1");
      }
      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      //setWorkHisstories(apiDataObj);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchDraftJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    token: user.token,
    file_name: null,
    job_id: localStorage.getItem("job_id"),
  });

  const [attachments, setAttatchments] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchJobAttatchments = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        job_id: localStorage.getItem("job_id"),
      };
      const apiData = await api.postMethod(param, "client/get-job-attatchments");

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setAttatchments(apiData.data);

      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchJobAttatchments();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const handleFileChange = (e) => {
    setformData((prevState) => ({
      ...prevState,
      file_name: e.target.files[0], // Update image field with the selected file
    }));
    /*
    setformData({
      ...formData,
      file_name: e.target.files[0], // Update image field with the selected file
    });
    */
    uploadAttachments();
  };

  const uploadAttachments = async () => {
    try {
      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/upload-job-attachments");
      if (result.status) {
        fetchJobAttatchments();
        document.getElementById("file_name").value = "";
        setIsLoading(false);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      const result = await api.postMethod(formData, "client/post-job");
      if (result) {
        setIsLoading(false);
        if (jobID) navigate("/client/job-post-step5/" + jobID, {
          state: { unique_id: formData.unique_id }
        });
        else navigate("/client/job-post-step5", {
          state: { unique_id: formData.unique_id }
        });
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const updateJob = async () => {
    toast.success("Job updated successfully");
    navigate("/client/jobs");
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Jobs", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Post a Job" breadcrumbItems={breadcrumbItems} />
              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item completed">
                      <div className="step-counter">1</div>
                      <div className="step-name">Position Details</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">2</div>
                      <div className="step-name">Location &amp; Salary</div>
                    </div>
                    <div className="stepper-item completed">
                      <div className="step-counter">3</div>
                      <div className="step-name">Qualification &amp; Responsibilities</div>
                    </div>
                    <div className="stepper-item active">
                      <div className="step-counter">4</div>
                      <div className="step-name">Attatchments</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <h4 className="header-title text-uppercase mb-2">Attatchments</h4>
                      <form
                        className="dropzone dz-clickable p-0 custom-file-upload-container position-relative"
                        id="resume"
                      >
                        <div className="dz-message needsclick">
                          <i className="h3 text-muted dripicons-cloud-upload" />
                          <h4>Drop or Click to Upload New Attatchments</h4>
                        </div>

                        <input
                          type="file"
                          name="file_name"
                          id="file_name"
                          onChange={handleFileChange}
                          accept="*"
                        />
                      </form>
                      {/* Preview */}
                      <div className="dropzone-previews mt-3" id="file-previews" />

                      {loading ? (
                        <p>Loading...</p>
                      ) : (
                        <>
                          {noRecordsFound ? (
                            <p>No attachment uploaded yet</p>
                          ) : (
                            <>
                              {Object.entries(attachments).map(([key, value]) => (
                                <div className="card mb-0 mt-2 shadow-none border" key={value.id}>
                                  <div className="p-2">
                                    <div className="row align-items-center">
                                      <div className="col-auto">
                                        <div className="avatar-sm">
                                          <span className="avatar-title badge-soft-primary text-primary rounded">
                                            {value.file_type}
                                          </span>
                                        </div>
                                      </div>
                                      <div className="col ps-0">
                                        <span className="text-muted fw-bold">{value.title}</span>
                                        <p className="mb-0 font-12">{value.file_size}</p>
                                      </div>
                                      <div className="col-auto">
                                        <a
                                          href={value.dir_path}
                                          download
                                          target="_blank"
                                          rel="noreferrer"
                                          className="btn btn-link font-16 text-muted"
                                        >
                                          <i className="dripicons-download" />
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ))}
                            </>
                          )}
                        </>
                      )}
                      <form onSubmit={handleFormSubmit}>
                        <div className="row mt-3">
                          <div className="col-md-6">
                            <Link to="/client/job-post-step3" className="btn btn-secondary">
                              <i className="fas fa-angle-left" /> Back
                            </Link>
                          </div>
                          <div className="col-md-6 text-lg-end">
                            {jobID ? (
                              <span onClick={updateJob} className="btn btn-primary">
                                Update Now <i className="fas fa-edit" />
                              </span>
                            ) : (
                              <button type="submit" className="btn btn-primary">
                                Publish Now <i className="fas fa-check" />
                              </button>
                            )}
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
            </div>
          </div>
          <Footer />
        </div>
      </div>
      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobPostStep4;
