import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, useNavigate, useLocation } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/dateFormat";
import useDebounce from "./Debounce";

const useQuery = () => {
  return new URLSearchParams(useLocation().search);
};

function Checklists() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const location = useLocation();
  const navigate = useNavigate();

  const [isLoading, setIsLoading] = useState(false);

  const [checklists, setChecklists] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const query = useQuery();

  const [filters, setFilters] = useState({
    keyword: "",
    type: "active",
  });

  useEffect(() => {
    const type = query.get("type");
    if (type && type !== filters.type) {
      setFilters((prevFilters) => ({
        ...prevFilters,
        type: type,
      }));
    }
  }, [query, filters.type]);

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      type: "active",
    };
    setFilters(defaultFilters);
  };

  // Handle form input change
  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    const newFilters =
      type === "checkbox" ? { ...filters, [name]: checked } : { ...filters, [name]: value };

    setFilters(newFilters);
    //updateUrlParams(newFilters);
  };

  // Update URL parameters based on filters
  const updateUrlParams = (filters) => {
    const queryParams = new URLSearchParams(location.search);
    queryParams.set("type", filters.type);

    // Remove parameters that are no longer present in filters
    if (filters.keyword === "") {
      queryParams.delete("keyword");
    } else {
      queryParams.set("keyword", filters.keyword);
    }

    const newUrl = `${location.pathname}?${queryParams.toString()}`;
    navigate(newUrl, { replace: true });
  };

  const debouncedFilters = useDebounce(filters, 500);

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    fetchAssignedChecklists();
  };

  const fetchAssignedChecklists = async () => {
    setLoading(true);
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...debouncedFilters };
      const apiData = await api.postMethod(queryParams, "user/get-assigned-checklists");

      setChecklists(apiData.data);
      setLoading(false);
      setNoRecordsFound(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedChecklists();
  }, [debouncedFilters]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Skill Checklists</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Skill Checklists</h4>
                  </div>
                </div>
              </div>

              <div className="card mb-3">
                <div className="card-body">
                  <form onSubmit={handleFilterSubmit}>
                    <div className="row">
                      <div className="col-lg-2">
                        <div className="form-group">
                          <label htmlFor="keyword" className="sr-only">
                            Search
                          </label>
                          <input
                            type="search"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            placeholder="Search..."
                            value={filters.keyword}
                            onChange={handleInputChange}
                          />
                        </div>
                      </div>
                      <div className="col-lg-2">
                        <select
                          className="form-control"
                          id="type"
                          value={filters.type}
                          onChange={handleInputChange}
                          name="type"
                        >
                          <option value="active">Select Skill Checklist</option>
                          <option value="assigned">Assigned Skill Checklist</option>
                          <option value="draft">Draft Skill Checklist</option>
                          <option value="completed">Completed Skill Checklist</option>
                        </select>
                      </div>
                      {/* 
                      <div className="col-lg-1">
                        <button type="submit" className="btn btn-primary waves-effect waves-light">
                          Submit
                        </button>
                      </div>
                      
                      <div className="col-lg-1">
                        <button
                          type="button"
                          onClick={handleReset}
                          className="btn btn-secondary waves-effect waves-light"
                        >
                          Reset
                        </button>
                      </div>*/}
                    </div>
                  </form>
                </div>
              </div>

              <div className="card mb-3 custom-box-shadow">
                <div className="card-header border-bottom">
                  <p className="mb-0 fw-bold">
                    Help us to understand your skills so we can find jobs that are a good fit for
                    you.
                  </p>
                </div>
                <div className="card-body">
                  <div className="table-responsive">
                    <table className="table table-centered table-nowrap mb-0">
                      <thead class="table-light">
                        <tr>
                          <th>Checklist Title</th>
                          {(filters.type == "draft" || filters.type == "completed") && (
                            <>
                              <th>Submitted On</th>
                              <th>Updated On</th>
                            </>
                          )}
                          <th>Actions</th>
                        </tr>
                      </thead>
                      <tbody>
                        {loading ? (
                          <tr>
                            <td
                              colSpan={
                                filters.type == "draft" || filters.type == "completed" ? 4 : 2
                              }
                            >
                              Loading...
                            </td>
                          </tr>
                        ) : (
                          <>
                            {noRecordsFound ? (
                              <tr>
                                <td
                                  colSpan={
                                    filters.type == "draft" || filters.type == "completed" ? 4 : 2
                                  }
                                >
                                  No record found
                                </td>
                              </tr>
                            ) : (
                              <>
                                {Object.entries(checklists).map(([key, value]) => (
                                  <tr key={value.id}>
                                    <td>{value.title}</td>
                                    {(filters.type == "draft" || filters.type == "completed") && (
                                      <>
                                        <td>
                                          {value.submitted_on ? (
                                            <>
                                              {formatDate(value.submitted_on, "MMM DD, YYYY")}{" "}
                                              <small className="text-muted">
                                                {formatDate(value.submitted_on, "hh:mm A")}
                                              </small>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                        <td>
                                          {value.updated_on ? (
                                            <>
                                              {formatDate(value.updated_on, "MMM DD, YYYY")}{" "}
                                              <small className="text-muted">
                                                {formatDate(value.updated_on, "hh:mm A")}
                                              </small>
                                            </>
                                          ) : (
                                            "-"
                                          )}
                                        </td>
                                      </>
                                    )}
                                    <td>
                                      <Link
                                        to={`/user/skill-checklist/` + value.slug}
                                        className="btn btn-secondary btn-xs"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content={
                                          filters.type == "draft"
                                            ? "Complete Skill Checklist"
                                            : filters.type == "active" || filters.type == "assigned"
                                            ? "Submit Skill Checklist"
                                            : "Update Skill Checklist"
                                        }
                                      >
                                        {filters.type == "draft"
                                          ? "Complete"
                                          : filters.type == "active" || filters.type == "assigned"
                                          ? "Start"
                                          : "Update"}
                                      </Link>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </td>
                                  </tr>
                                ))}
                              </>
                            )}
                          </>
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default Checklists;
