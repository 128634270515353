import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link, useNavigate } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

const ManageCompliance = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();
  const navigate = useNavigate();
  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    expiration_start_date: "",
    expiration_end_date: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      expiration_start_date: "",
      expiration_end_date: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [selectedTab, setSelectedTab] = useState("all");

  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
    fetchComplianceFiles(selectedTab);
  };

  const [assignedUsers, setAssignedUsers] = useState([]);
  const fetchAssignedUsers = async () => {
    try {
      const apiData = await api.getMethod("get-users/4");

      setAssignedUsers(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchAssignedUsers();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [complianceTypes, setComplianceTypes] = useState([]);
  const fetchComplianceTypes = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-compliance-file-types");

      setComplianceTypes(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceTypes();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [categories, setCategories] = useState([]);
  const fetchDocumentCategories = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "admin/get-compliance-file-categories");

      setCategories(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchDocumentCategories();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async (tab) => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
        tab: tab,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-compliance-files");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles(selectedTab);
  }, [selectedTab]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    title: null,
    type_id: null,
    expiration_date: null,
    file_name: null,
    file_type: null,
    cat_id: null,
    assigned_user_id: null,
    notes: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        title: null,
        type_id: null,
        expiration_date: null,
        file_name: null,
        file_type: null,
        cat_id: null,
        assigned_user_id: null,
        notes: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      title: record.title,
      type_id: record.type_id,
      expiration_date: record.expiration_date,
      file_name: record.file_name,
      file_type: record.file_type,
      cat_id: record.cat_id,
      assigned_user_id: record.assigned_user_id,
      notes: record.notes,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-compliance-file");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    title: Yup.string().required("Title is Required"),
    type_id: Yup.string().required("Type is Required"),
    expiration_date: Yup.string().required("Expiration Date is Required"),
    /*file_name: Yup.string().required("File is Required"),*/
    cat_id: Yup.string().required("Category is Required"),
    assigned_user_id: Yup.string().required("User is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-compliance-file");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const handleFileChange = (e) => {
    setformData({
      ...formData,
      file_name: e.target.files[0], // Update image field with the selected file
    });
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = complianceFiles.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "admin/compliance-files-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchComplianceFiles();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedUserIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedUserIds.includes(record.id)));
          setSelectedUserIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedUserIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedUserIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedUserIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-compliance-file-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Compliance Files", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Compliance Files" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-header border-bottom">
              <div className="row row-cols-4">
                {/*
                        <div className="col my-1">
                          <button
                            key="all"
                            type="button"
                            className={
                              "btn  w-100 " + (selectedTab === "all" ? "btn-primary" : "btn-light")
                            }
                            onClick={() => handleTabSelect("all")}
                          >
                            All Categories ({complianceFiles.length})
                          </button>
                        </div>
                         */}
                {categories.length > 0 &&
                  categories.map((etype) => (
                    <div className="col my-1" key={etype.id}>
                      <button
                        type="button"
                        className={
                          "btn  w-100 " +
                          (etype.id === null && selectedTab === "all"
                            ? "btn-primary"
                            : selectedTab === etype.id
                            ? "btn-primary"
                            : "btn-light")
                        }
                        onClick={() => handleTabSelect(etype.id)}
                      >
                        {etype.cat_name} ({etype.total_compliance_files})
                      </button>
                    </div>
                  ))}
              </div>
            </div>

            <div className="card-body">
              <div className="row justify-content-between mb-2">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>

                      {filters.status == "archived" ? (
                        <option value="remove-from-archive">Remove from Archive</option>
                      ) : (
                        <option value="mark-as-archive">Mark as Archive</option>
                      )}

                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                      <option value="delete">Delete</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="expiration_start_date" className="form-label">
                            Expiration Date Range
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              name="expiration_start_date"
                              className="form-control"
                              value={filters.expiration_start_date}
                              onChange={handleFilterInputChange}
                            />
                            <span className="input-group-text">To</span>
                            <input
                              type="date"
                              name="expiration_end_date"
                              className="form-control"
                              value={filters.expiration_end_date}
                              onChange={handleFilterInputChange}
                            />
                          </div>
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={"active"}>Active</option>
                            <option value={"expired"}>Expired</option>
                            <option value={"archived"}>Archive</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2 me-1"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1"></i> Add New
                    </button>
                  </div>
                </div>
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === complianceFiles.length &&
                            complianceFiles.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Title</th>
                      <th>Type</th>
                      <th>Posted By</th>
                      <th>Assigned To</th>
                      <th>Expiration Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="8">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    value={value.id}
                                    name="user_ids"
                                    onChange={handleCheckboxChange}
                                    checked={selectedUserIds.includes(value.id)}
                                    className="form-check-input"
                                  />
                                </td>
                                <td>{value.title}</td>
                                <td>{value.doc_type_name}</td>
                                <td>
                                  {(value.creator_role_id == 2 && (
                                    <Link
                                      to={`/admin/facility-profile/` + value.creator_unique_id}
                                      className=" "
                                    >
                                      {value.creator_name}
                                    </Link>
                                  )) ||
                                    (value.creator_role_id == 3 && (
                                      <Link
                                        to={`/admin/agency-profile/` + value.creator_unique_id}
                                        className=" "
                                      >
                                        {value.creator_name}
                                      </Link>
                                    )) || <span className="">{value.creator_name}</span>}
                                </td>
                                <td>
                                  <Link
                                    to={{
                                      pathname: `/admin/user-profile/${value.assigned_unique_id}`,
                                      state: { tab: "compliance-files" },
                                    }}
                                  >
                                    {value.assigned_to}
                                  </Link>
                                </td>
                                <td>{formatDate(value.expiration_date, "MMM DD, YYYY")}</td>
                                <td>
                                  {value.status == 1 && (
                                    <label className="badge badge-soft-success">Active</label>
                                  )}
                                  {value.status == 0 && (
                                    <label className="badge badge-soft-warning">In-Active</label>
                                  )}
                                </td>
                                <td>
                                  {value.dir_path && (
                                    <>
                                      <Link
                                        to={value.dir_path}
                                        download
                                        target="_blank"
                                        rel="noreferrer"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-4"
                                        data-tooltip-content="Download Document"
                                      >
                                        <i className="mdi mdi-download" />
                                      </Link>
                                      <Tooltip id="mt-4"></Tooltip>
                                    </>
                                  )}

                                  {value.status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Edit Compliance File"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Compliance File"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Compliance File</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="title">
                  Title
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="title"
                  name="title"
                  onChange={handleChange}
                  defaultValue={formData.title !== null ? formData.title : ""}
                />
                {errors.title && <span className="error">{errors.title}</span>}
              </div>
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="type_id">
                  Type
                </label>
                <select
                  className="form-control"
                  id="type_id"
                  name="type_id"
                  onChange={handleChange}
                  value={formData.type_id && formData.type_id}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {complianceTypes.map((dtype) => (
                    <option key={dtype.id} value={dtype.id}>
                      {dtype.doc_name}
                    </option>
                  ))}
                </select>
                {errors.type_id && <span className="error">{errors.type_id}</span>}
              </div>
              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="expiration_date">
                  Expiration Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="expiration_date"
                  name="expiration_date"
                  onChange={handleChange}
                  defaultValue={formData.expiration_date !== null ? formData.expiration_date : ""}
                />
                {errors.expiration_date && <span className="error">{errors.expiration_date}</span>}
              </div>
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="file_name">
                  Document
                </label>
                <input
                  type="file"
                  className="form-control"
                  id="file_name"
                  name="file_name"
                  onChange={handleFileChange}
                />
                {/*errors.file_name && <span className="error">{errors.file_name}</span>*/}
              </div>
              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="cat_id">
                  Category
                </label>
                <select
                  className="form-control"
                  id="cat_id"
                  name="cat_id"
                  onChange={handleChange}
                  value={formData.cat_id !== null ? formData.cat_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {categories.map((cat) => (
                    <option key={cat.id} value={cat.id}>
                      {cat.cat_name}
                    </option>
                  ))}
                </select>
                {errors.cat_id && <span className="error">{errors.cat_id}</span>}
              </div>
              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="assigned_user_id">
                  Assigned to Candidate
                </label>
                <select
                  className="form-control"
                  id="assigned_user_id"
                  name="assigned_user_id"
                  onChange={handleChange}
                  value={formData.assigned_user_id !== null ? formData.assigned_user_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {assignedUsers.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {errors.assigned_user_id && (
                  <span className="error">{errors.assigned_user_id}</span>
                )}
              </div>
              <div className="mb-0 col-md-12">
                <label className="form-label" htmlFor="notes">
                  Description
                </label>
                <textarea
                  id="notes"
                  className="form-control"
                  name="notes"
                  onChange={handleChange}
                  rows="5"
                  value={formData.notes !== null ? formData.notes : ""}
                ></textarea>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default ManageCompliance;
