import React, { useState, useEffect } from "react";
import { NavLink, useNavigate, useLocation } from "react-router-dom";
import * as auth from "../../../services/AuthService";
import * as api from "../../../services/ApiService";

function SidebarClient({ user }) {
  let navigate = useNavigate();
  const location = useLocation();

  // logout function
  const handleLogout = async () => {
    try {
      // Call function and API endpoint
      let res = await auth.logout({ user_id: user.id, token: user.token }, "client/logout");
      if (res) {
        setTimeout(() => {
          navigate("/client/login");
        }, 500);
      }
    } catch (error) {
      console.error("Unexpected error structure:", error);
    }
  };

  const getClassNames = (path) => {
    return location.pathname === path ? "menu-item menuitem-active" : "menu-item";
  };

  const [messages, setMessages] = useState(0);
  const [newJobApplicationCountes, setNewJobApplicationCountes] = useState(0);

  useEffect(() => {
    const fetchSidebarMeta = async () => {
      try {
        const param = {
          user_id: user.id,
        };
        const result = await api.postMethod(param, "client/get-sidebar-data");
        if (result.status) {
          const apiData = result.data;

          setMessages(apiData.messages);
          setNewJobApplicationCountes(apiData.applications);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchSidebarMeta();

    // Set up the interval to call fetchSidebarMeta every 5 seconds
    const interval = setInterval(fetchSidebarMeta, 5000);

    // Cleanup function to clear the interval when the component unmounts
    return () => clearInterval(interval);
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <ul className="menu">
      <li className="menu-title">Navigation</li>
      <li className={getClassNames("/client/dashboard")}>
        <NavLink to="/client/dashboard" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-home" />
          </span>
          <span className="menu-text"> Dashboard </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/sms") || getClassNames("/client/inbox")}>
        <a href="#messages" data-bs-toggle="collapse" className="menu-link">
          <span className="menu-icon">
            <i className="fas fa-comments"></i>
          </span>
          <span className="menu-text"> Messaging </span>
          <span className="menu-arrow" />
        </a>
        <div className="collapse" id="messages">
          <ul className="sub-menu">
            <li className={getClassNames("/client/inbox")}>
              <NavLink to="/client/inbox" className="menu-link" activeClassName="active">
                {/* 
                <span className="menu-icon">
                  <i className="fas fa-envelope" />
                </span>
                */}
                <span className="menu-text"> Inbox </span>
                {messages > 0 && (
                  <span class="badge badge-outline-primary badge-pill ms-auto">{messages}</span>
                )}
              </NavLink>
            </li>
            <li className={getClassNames("/client/sms")}>
              <NavLink to="/client/sms" className="menu-link " activeClassName="active">
                {" "}
                <span className="menu-text"> SMS </span>
              </NavLink>
            </li>
          </ul>
        </div>
      </li>
      {/*
      <li className={getClassNames("/client/inbox")}>
        <NavLink to="/client/inbox" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-envelope" />
          </span>
          <span className="menu-text"> Inbox </span>
          <span class="badge badge-outline-primary badge-pill ms-auto">{messages}</span>
        </NavLink>
        </li>
        */}
      <li className={getClassNames("/client/calendar")}>
        <NavLink to="/client/calendar" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-calendar-alt" />
          </span>
          <span className="menu-text"> Calendar </span>
        </NavLink>
      </li>
      <li className="menu-title">Jobs</li>
      <li className={getClassNames("/client/jobs")}>
        <NavLink to="/client/jobs" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-briefcase" />
          </span>
          <span className="menu-text"> Jobs </span>
        </NavLink>
      </li>
      {/* 
      <li className={getClassNames("/client/search")}>
        <NavLink to="/client/search" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-search" />
          </span>
          <span className="menu-text"> Search Resume</span>
        </NavLink>
      </li>
      */}
      <li className={getClassNames("/client/job-applications")}>
        <NavLink to="/client/job-applications" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-address-card" />
          </span>
          <span className="menu-text"> Job Applications</span>
          {newJobApplicationCountes > 0 && (
            <span class="badge badge-outline-primary badge-pill ms-auto">
              {newJobApplicationCountes}
            </span>
          )}
        </NavLink>
      </li>

      <li className="menu-title">HR Management</li>

      <li className={getClassNames("/client/follow-ups")}>
        <NavLink to="/client/follow-ups" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-podcast" />
          </span>
          <span className="menu-text"> Follow Ups </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/applicants")}>
        <NavLink to="/client/applicants" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Applicants </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/candidates")}>
        <NavLink to="/client/candidates" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Candidates </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/employees")}>
        <NavLink to="/client/employees" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Employees </span>
        </NavLink>
      </li>

      {user.role_id == 2 && (
        <>
          <li className={getClassNames("/client/assignments")}>
            <NavLink to="/client/assignments" className="menu-link" activeClassName="active">
              <span className="menu-icon">
                <i className="fas fa-edit" />
              </span>
              <span className="menu-text"> Assignments </span>
            </NavLink>
          </li>
        </>
      )}

      <li className="menu-title">Compliance Files</li>

      <li className={getClassNames("/client/compliance-files")}>
        <NavLink to="/client/compliance-files" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-folder" />
          </span>
          <span className="menu-text"> Manage </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/assign-checklist")}>
        <NavLink to="/client/assign-checklist" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-edit" />
          </span>
          <span className="menu-text"> Skill Checklists </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/facility-compliances")}>
        <NavLink to="/client/facility-compliances" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-list" />
          </span>
          <span className="menu-text"> Facility Compliance Lists </span>
        </NavLink>
      </li>

      <li className={getClassNames("/client/shared-documents")}>
        <NavLink to="/client/shared-documents" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-folder-open" />
          </span>
          <span className="menu-text"> Shared Documents </span>
        </NavLink>
      </li>

      <li className="menu-title">Tasks</li>

      <li className={getClassNames("/client/tasks")}>
        <NavLink to="/client/tasks" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-tasks" />
          </span>
          <span className="menu-text"> Tasks </span>
        </NavLink>
      </li>

      {/* 
      <li className="menu-title">Scheduling</li>
      <li className={getClassNames("/client/scheduling")}>
        <NavLink to="/client/scheduling" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-calendar-alt" />
          </span>
          <span className="menu-text"> Scheduling </span>
        </NavLink>
      </li>
      */}

      <li className="menu-title">Account Management</li>

      {user.role_id == 3 && (
        <>
          {/* 
          <li className={getClassNames("/client/clients")}>
            <NavLink to="/client/clients" className="menu-link" activeClassName="active">
              <span className="menu-icon">
                <i className="fas fa-users" />
              </span>
              <span className="menu-text"> Clients </span>
            </NavLink>
          </li>
          */}

          <li className={getClassNames("/client/assignments")}>
            <NavLink to="/client/assignments" className="menu-link" activeClassName="active">
              <span className="menu-icon">
                <i className="fas fa-edit" />
              </span>
              <span className="menu-text"> Assignments </span>
            </NavLink>
          </li>
        </>
      )}

      <li className={getClassNames("/client/submissions")}>
        <NavLink to="/client/submissions" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-paper-plane" />
          </span>
          <span className="menu-text"> Submissions </span>
        </NavLink>
      </li>

      {/* 
      <li className={getClassNames("/client/redirects")}>
        <NavLink to="/client/redirects" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-external-link-alt" />
          </span>
          <span className="menu-text"> Redirects </span>
        </NavLink>
      </li>
      */}

      {/* 
      <li className="menu-title mt-2">Others</li>
      <li className={getClassNames("/client/sms") || getClassNames("/client/email")}>
        <a href="#messages" data-bs-toggle="collapse" className="menu-link  ">
          <span className="menu-icon">
            <i className="fas fa-comments"></i>
          </span>
          <span className="menu-text"> Messages </span>
          <span className="menu-arrow" />
        </a>
        <div className="collapse" id="messages">
          <ul className="sub-menu">
            <li className={getClassNames("/client/sms")}>
              <NavLink to="/client/sms" className="menu-link ">
                {" "}
                <span className="menu-text"> SMS </span>
              </NavLink>
            </li>
            <!-- 
            <li className={getClassNames("/client/email")}>
              <NavLink to="/client/email" className="menu-link ">
                <span className="menu-text"> Email </span>
              </NavLink>
            </li>
            -->
          </ul>
        </div>
      </li>
      */}

      <li className="menu-title">Admin</li>
      <li className={getClassNames("/client/company-profile")}>
        <NavLink to="/client/company-profile" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-user-tie" />
          </span>
          <span className="menu-text"> Company Profile </span>
        </NavLink>
      </li>
      <li className={getClassNames("/client/office-admins")}>
        <NavLink to="/client/office-admins" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-users" />
          </span>
          <span className="menu-text"> Office Admins </span>
        </NavLink>
      </li>

      {/* 
      <li className={getClassNames("/client/dashbosubscription-plansard")}>
        <NavLink to="/client/subscription-plans" className="menu-link" activeClassName="active">
          <span className="menu-icon">
            <i className="fas fa-box-open" />
          </span>
          <span className="menu-text"> Subscription Plans </span>
        </NavLink>
      </li>
      */}

      <li className="menu-item">
        <NavLink
          to="#"
          onClick={() => handleLogout()}
          className="menu-link"
          activeClassName="active"
        >
          <span className="menu-icon">
            <i className="fas fa-sign-out-alt" />
          </span>
          <span className="menu-text"> Logout </span>
        </NavLink>
      </li>
    </ul>
  );
}

export default SidebarClient;
