import "jquery-ui-dist/jquery-ui";

import { BrowserRouter as Router, Route, Routes, useLocation } from "react-router-dom";
import React, { useEffect } from "react";

/* User Auth Compnents */
import Login from "./component/Auth/User/Login";
import Register from "./component/Auth/User/Register";
import EmailVerification from "./component/Auth/User/EmailVerification";
import ForgotPassword from "./component/Auth/User/ForgotPassword";
import ResetPassword from "./component/Auth/User/ResetPassword";

/* User Compnents */
import Dashboard from "./component/User/Dashboard";
import Jobs from "./component/User/Job/Jobs";
import ShortListedJobs from "./component/User/Job/ShortListedJobs";
import JobDetail from "./component/User/Job/JobDetail";
import UserCompanyProfile from "./component/User/Job/CompanyProfile";
import AppliedJobs from "./component/User/Job/AppliedJobs";
import Calendar from "./component/User/Calendar";
import Messages from "./component/User/Messages";
import Documents from "./component/User/Document/Documents";
import DocumentShareHistory from "./component/User/Document/DocumentShareHistory";
import DocumentAccessRequests from "./component/User/Document/DocumentAccessRequests";
import Profile from "./component/User/Profile/Profile";
import Checklists from "./component/User/Checklist/Checklists";
import ChecklistDetails from "./component/User/Checklist/ChecklistDetails";
import SubscriptionPlans from "./component/User/SubscriptionPlans/Subscriptions";
import Checkout from "./component/User/SubscriptionPlans/Checkout";
import Settings from "./component/User/Settings";

/* Client Auth Compnents */
import ClientLogin from "./component/Auth/Client/Login";
import ClientRegister from "./component/Auth/Client/Register";
import ClientEmailVerification from "./component/Auth/Client/EmailVerification";
import ClientForgotPassword from "./component/Auth/Client/ForgotPassword";
import ClientResetPassword from "./component/Auth/Client/ResetPassword";

/* Client Compnents */
import ClientDashboard from "./component/Client/Dashboard";
import ClientInbox from "./component/Client/Messages";
import ClientCalendar from "./component/Client/Calendar";

import ClientJobs from "./component/Client/Job/Jobs";
import ClientJobPostStep1 from "./component/Client/Job/JobPostStep1";
import ClientJobPostStep2 from "./component/Client/Job/JobPostStep2";
import ClientJobPostStep3 from "./component/Client/Job/JobPostStep3";
import ClientJobPostStep4 from "./component/Client/Job/JobPostStep4";
import ClientJobPostStep5 from "./component/Client/Job/JobPostStep5";
import ClientJobApplications from "./component/Client/Job/JobApplications";
import ClientJobDetail from "./component/Client/Job/JobDetail";
import ClientSearch from "./component/Client/Job/Search";

import ClientDocuments from "./component/Client/Document/Documents";
import ClientDocumentShareHistory from "./component/Client/Document/DocumentShareHistory";
import ClientDocumentTypes from "./component/Client/Document/DocumentTypes";

import ClientFacilityClients from "./component/Client/AccountManagement/ManageClients";
import ClientAssignments from "./component/Client/AccountManagement/ManageAssignments";
import ClientSubmissions from "./component/Client/AccountManagement/ManageSubmissions";
import ClientRedirects from "./component/Client/AccountManagement/ManageRedirects";

import ClientComplianceFiles from "./component/Client/ComplianceFiles/ComplianceFiles";
import ClientComplianceTypes from "./component/Client/ComplianceFiles/ComplianceTypes";
import ClientComplianceCategories from "./component/Client/ComplianceFiles/ClientComplianceCategories";

import ClientFacilityCompliances from "./component/Client/ComplianceFiles/ClientFacilityCompliances";
import ClientAssignFacilityCompliances from "./component/Client/ComplianceFiles/AssignFacilityCompliances";

import ClientComplianceChecklists from "./component/Client/ComplianceFiles/ComplianceChecklists";
import ClientComplianceAddChecklist from "./component/Client/ComplianceFiles/ComplianceAddChecklist";
import ClientAssignChecklist from "./component/Client/ComplianceFiles/AssignChecklist";
import ClientSubmittedChecklists from "./component/Client/ComplianceFiles/SubmittedChecklist";

import ClientProfile from "./component/Client/Profile/Profile";
import ClietScheduling from "./component/Client/Scheduling/Scheduling";
import ClientApplicants from "./component/Client/Applicants/ManageApplicants";

import ClientCandidates from "./component/Client/Candidates/ManageCandidates";
import ClientAddCandidateStep1 from "./component/Client/Candidates/AddCandidateStep1";
import ClientAddCandidateStep2 from "./component/Client/Candidates/AddCandidateStep2";
import ClientAddCandidateStep3 from "./component/Client/Candidates/AddCandidateStep3";
import ClientAddCandidateStep4 from "./component/Client/Candidates/AddCandidateStep4";
import ClientAddCandidateStep5 from "./component/Client/Candidates/AddCandidateStep5";
import ClientUserProfile from "./component/Client/Candidates/UserProfile";

import ClientEmployees from "./component/Client/Employees/ManageEmployees";
import ClientAddEmployeeStep1 from "./component/Client/Employees/AddEmployeeStep1";
import ClientAddEmployeeStep2 from "./component/Client/Employees/AddEmployeeStep2";
import ClientAddEmployeeStep3 from "./component/Client/Employees/AddEmployeeStep3";
import ClientAddEmployeeStep4 from "./component/Client/Employees/AddEmployeeStep4";
import ClientAddEmployeeStep5 from "./component/Client/Employees/AddEmployeeStep5";

import ClientOfficeAdmins from "./component/Client/OfficeAdmins/OfficeAdmins";
import ClientInterviews from "./component/Client/Interviews/ManageInterviews";

import ClientTasks from "./component/Client/Tasks/Tasks";
import ClientSubscriptionPlans from "./component/Client/SubscriptionPlans/Subscriptions";
import ClientCheckout from "./component/Client/SubscriptionPlans/Checkout";

import ClientSMS from "./component/Client/OtherSection/SMS";
import ClientEmail from "./component/Client/OtherSection/Email";

// Admin Routes
/* Client Auth Compnents */
import AdminLogin from "./component/Auth/Admin/Login";
import AdminEmailVerification from "./component/Auth/Admin/EmailVerification";
import AdminForgotPassword from "./component/Auth/Admin/ForgotPassword";
import AdminResetPassword from "./component/Auth/Admin/ResetPassword";
import AdminDashboard from "./component/Admin/AdminDashboard";

import AdminJobs from "./component/Admin/JobSection/AdminJobs";
import AdminJobPostStep1 from "./component/Admin/JobSection/JobPost/JobPostStep1";
import AdminJobPostStep2 from "./component/Admin/JobSection/JobPost/JobPostStep2";
import AdminJobPostStep3 from "./component/Admin/JobSection/JobPost/JobPostStep3";
import AdminJobPostStep4 from "./component/Admin/JobSection/JobPost/JobPostStep4";
import AdminJobPostStep5 from "./component/Admin/JobSection/JobPost/JobPostStep5";
import AdminJobDetail from "./component/Admin/JobSection/AdminJobDetail";
import AdminJobApplocation from "./component/Admin/JobSection/AdminJobApplocation";
import AdminSearchResume from "./component/Admin/JobSection/Search";

import AdminInterviewList from "./component/Admin/HRManagement/Interviews/AdminInterviewList";
import AdminApplicantsList from "./component/Admin/HRManagement/Applicants/AdminApplicantsList";

import AdminCandidatesList from "./component/Admin/HRManagement/Candidates/AdminCandidatesList";
import AdminAddCandidateStep1 from "./component/Admin/HRManagement/Candidates/AddCandidateStep1";
import AdminAddCandidateStep2 from "./component/Admin/HRManagement/Candidates/AddCandidateStep2";
import AdminAddCandidateStep3 from "./component/Admin/HRManagement/Candidates/AddCandidateStep3";
import AdminAddCandidateStep4 from "./component/Admin/HRManagement/Candidates/AddCandidateStep4";
import AdminAddCandidateStep5 from "./component/Admin/HRManagement/Candidates/AddCandidateStep5";

import AdminEmloyeesList from "./component/Admin/HRManagement/Employees/AdminEmloyeesList";
import AdminAddEmployeeStep1 from "./component/Admin/HRManagement/Employees/AddEmployeeStep1";
import AdminAddEmployeeStep2 from "./component/Admin/HRManagement/Employees/AddEmployeeStep2";
import AdminAddEmployeeStep3 from "./component/Admin/HRManagement/Employees/AddEmployeeStep3";
import AdminAddEmployeeStep4 from "./component/Admin/HRManagement/Employees/AddEmployeeStep4";
import AdminAddEmployeeStep5 from "./component/Admin/HRManagement/Employees/AddEmployeeStep5";

import AdminAgenciesList from "./component/Admin/HRManagement/Agencies/AdminAgenciesList";
import AdminAgenciesDetail from "./component/Admin/HRManagement/Agencies/AdminAgenciesDetail";
import AdminEmloyers from "./component/Admin/HRManagement/Employers/AdminEmloyers";
import AdminEmployerDetail from "./component/Admin/HRManagement/Employers/AdminEmployerDetail";
import AdminInterviewers from "./component/Admin/HRManagement/Interviewers/AdminInterviewers";
import ManageApplicants from "./component/Client/Applicants/ManageApplicants";
import ManageCompliance from "./component/Admin/Compliance/ManageCompliance";
import ComplianceCategory from "./component/Admin/Compliance/ComplianceCategory";
import ComplianceTypes from "./component/Admin/Compliance/ComplianceTypes";
import FacilityComplianceLists from "./component/Admin/Compliance/FacilityComplianceLists";

import AdminComplianceChecklists from "./component/Admin/Compliance/ComplianceChecklists";
import AdminComplianceAddChecklist from "./component/Admin/Compliance/ComplianceAddChecklist";
import AdminComplianceSubmittedChecklists from "./component/Admin/Compliance/ComplianceSubmittedChecklist";
import AdminComplianceAssignChecklist from "./component/Admin/Compliance/ComplianceAssignChecklists";

import ComplianceDocument from "./component/Admin/Compliance/ComplianceDocument";
import AdminDocumentShareHistory from "./component/Admin/Compliance/ComplianceDocumentShareHistory";
import AdminiDocumentTypes from "./component/Admin/Compliance/ComplianceDocumentTypes";
import Tasks from "./component/Admin/Tasks/Tasks";
import CompanyProfile from "./component/Admin/AdminSection/CompanyProfile";
import SubAdmin from "./component/Admin/AdminSection/SubAdmin";
import Professions from "./component/Admin/AdminSection/Professions";
import Specialities from "./component/Admin/AdminSection/Specialities";
import RecruitmentTeam from "./component/Admin/AdminSection/RecruitmentTeam";
import ReportsAnalytics from "./component/Admin/AdminSection/ReportsAnalytics";
import Announcements from "./component/Admin/AdminSection/Announcements";
import Skills from "./component/Admin/AdminSection/Skills";
import Scheduling from "./component/Admin/Scheduling/Scheduling";
import Clients from "./component/Admin/AccountManagement/Clients";
import Assignments from "./component/Admin/AccountManagement/Assignments";
import Submissions from "./component/Admin/AccountManagement/Submissions";
import Redirects from "./component/Admin/AccountManagement/Redirects";
import HelmetComponent, { getPageTitle, getRoleById } from "./component/Layout/HelmetComponent";
import AdminSMS from "./component/Admin/OtherSection/AdminSMS";
import AdminEmail from "./component/Admin/OtherSection/AdminEmail";
import UserProfile from "./component/Admin/HRManagement/UserProfile/UserProfile";
import ActivityLog from "./component/Admin/OtherSection/ActivityLog";
import AdminContactEnquiries from "./component/Admin/OtherSection/AdminContactEnquiries";
import AdminCalender from "./component/Admin/AdminCalender";
import AdminBlogs from "./component/Admin/OtherSection/AdminBlogs";

import { getTokenData } from "./utils/tokenUtils";

function App() {
  const location = useLocation();
  // Get the API base url from env
  //const apiUrl = process.env.REACT_APP_API_URL;

  const user = getTokenData();
  const currentRole = getRoleById(user?.role_id || "");

  let pageTitle = getPageTitle(location.pathname, currentRole);
  console.log(pageTitle);
  useEffect(() => {
    pageTitle = getPageTitle(location.pathname);
  }, [location.pathname]);

  return (
    <>
      {/* basename={process.env.PUBLIC_URL} */}
      <HelmetComponent title={pageTitle} />

      <Routes>
        {/* === User Auth Routes === */}
        <Route exact path="/" Component={Login}></Route>
        <Route exact path="/register" Component={Register}></Route>
        <Route exact path="/email-verification" Component={EmailVerification}></Route>
        <Route exact path="/forgot-password" Component={ForgotPassword}></Route>
        <Route exact path="/reset-password" Component={ResetPassword}></Route>
        <Route exact path="/dashboard" Component={Dashboard}></Route>

        {/* === User Routes === */}
        <Route path="/user">
          <Route exact path="jobs" Component={Jobs}></Route>
          <Route exact path="saved-jobs" Component={ShortListedJobs}></Route>
          <Route exact path="job/:jobId" Component={JobDetail}></Route>
          <Route exact path="company-profile/:cId" Component={UserCompanyProfile}></Route>
          <Route exact path="applied-jobs" Component={AppliedJobs}></Route>
          <Route exact path="calendar" Component={Calendar}></Route>
          <Route exact path="messages" Component={Messages}></Route>
          <Route exact path="documents" Component={Documents}></Route>
          <Route exact path="document-share-history" Component={DocumentShareHistory}></Route>
          <Route exact path="document-access-requests" Component={DocumentAccessRequests}></Route>
          <Route exact path="profile" Component={Profile}></Route>
          {/* <Route exact path="subscription-plans" Component={SubscriptionPlans}></Route>*/}
          <Route exact path="checkout" Component={Checkout}></Route>
          <Route exact path="skill-checklists" Component={Checklists}></Route>
          <Route exact path="skill-checklist/:checklistId" Component={ChecklistDetails}></Route>
          <Route exact path="settings" Component={Settings}></Route>
        </Route>

        {/* === Client Auth Routes === */}
        <Route exact path="/client/login" Component={ClientLogin}></Route>
        <Route exact path="/client/register" Component={ClientRegister}></Route>
        <Route exact path="/client/email-verification" Component={ClientEmailVerification}></Route>
        <Route exact path="/client/forgot-password" Component={ClientForgotPassword}></Route>
        <Route exact path="/client/reset-password" Component={ClientResetPassword}></Route>
        <Route exact path="/client/dashboard" Component={ClientDashboard}></Route>

        {/* === Client Routes === */}
        <Route path="/client">
          <Route exact path="inbox" Component={ClientInbox}></Route>
          <Route exact path="calendar" Component={ClientCalendar}></Route>

          <Route exact path="jobs" Component={ClientJobs}></Route>
          <Route exact path="boosted-jobs" Component={ClientJobs}></Route>
          <Route exact path="job-post-step1" Component={ClientJobPostStep1}></Route>
          <Route exact path="job-post-step1/:jobID" Component={ClientJobPostStep1}></Route>
          <Route exact path="job-post-step2" Component={ClientJobPostStep2}></Route>
          <Route exact path="job-post-step2/:jobID" Component={ClientJobPostStep2}></Route>
          <Route exact path="job-post-step3" Component={ClientJobPostStep3}></Route>
          <Route exact path="job-post-step3/:jobID" Component={ClientJobPostStep3}></Route>
          <Route exact path="job-post-step4" Component={ClientJobPostStep4}></Route>
          <Route exact path="job-post-step4/:jobID" Component={ClientJobPostStep4}></Route>
          <Route exact path="job-post-step5" Component={ClientJobPostStep5}></Route>
          <Route exact path="job-post-step5/:jobID" Component={ClientJobPostStep5}></Route>
          <Route exact path="company-profile" Component={ClientProfile}></Route>
          <Route exact path="job-applications" Component={ClientJobApplications}></Route>
          <Route exact path="job/:jobId" Component={ClientJobDetail}></Route>
          <Route exact path="search" Component={ClientSearch}></Route>

          <Route exact path="shared-documents" Component={ClientDocuments}></Route>
          <Route exact path="document-share-history" Component={ClientDocumentShareHistory}></Route>
          <Route exact path="document-types" Component={ClientDocumentTypes}></Route>

          <Route exact path="clients" Component={ClientFacilityClients}></Route>
          <Route exact path="assignments" Component={ClientAssignments}></Route>
          <Route exact path="submissions" Component={ClientSubmissions}></Route>
          <Route exact path="redirects" Component={ClientRedirects}></Route>

          <Route exact path="scheduling" Component={ClietScheduling}></Route>

          <Route exact path="compliance-files" Component={ClientComplianceFiles}></Route>
          <Route exact path="compliance-file-types" Component={ClientComplianceTypes}></Route>
          <Route
            exact
            path="compliance-file-categories"
            Component={ClientComplianceCategories}
          ></Route>

          <Route exact path="facility-compliances" Component={ClientFacilityCompliances}></Route>
          <Route
            exact
            path="assign-facility-compliance"
            Component={ClientAssignFacilityCompliances}
          ></Route>

          <Route exact path="compliance-checklists" Component={ClientComplianceChecklists}></Route>
          <Route exact path="submitted-checklists" Component={ClientSubmittedChecklists}></Route>
          <Route exact path="add-checklist" Component={ClientComplianceAddChecklist}></Route>
          <Route
            exact
            path="add-checklist/:checklistID"
            Component={ClientComplianceAddChecklist}
          ></Route>
          <Route exact path="assign-checklist" Component={ClientAssignChecklist}></Route>

          <Route exact path="applicants" Component={ClientApplicants}></Route>

          <Route exact path="employees" Component={ClientEmployees}></Route>
          <Route exact path="add-employee-step1" Component={ClientAddEmployeeStep1}></Route>
          <Route exact path="add-employee-step1/:userID" Component={ClientAddEmployeeStep1}></Route>
          <Route exact path="add-employee-step2" Component={ClientAddEmployeeStep2}></Route>
          <Route exact path="add-employee-step3" Component={ClientAddEmployeeStep3}></Route>
          <Route exact path="add-employee-step4" Component={ClientAddEmployeeStep4}></Route>
          <Route exact path="add-employee-step5" Component={ClientAddEmployeeStep5}></Route>

          <Route exact path="interviews" Component={ClientInterviews}></Route>
          <Route exact path="follow-ups" Component={ClientInterviews}></Route>
          <Route exact path="office-admins" Component={ClientOfficeAdmins}></Route>

          <Route exact path="candidates" Component={ClientCandidates}></Route>
          <Route exact path="add-candidate-step1" Component={ClientAddCandidateStep1}></Route>
          <Route
            exact
            path="add-candidate-step1/:userID"
            Component={ClientAddCandidateStep1}
          ></Route>
          <Route exact path="add-candidate-step2" Component={ClientAddCandidateStep2}></Route>
          <Route exact path="add-candidate-step3" Component={ClientAddCandidateStep3}></Route>
          <Route exact path="add-candidate-step4" Component={ClientAddCandidateStep4}></Route>
          <Route exact path="add-candidate-step5" Component={ClientAddCandidateStep5}></Route>
          <Route exact path="user-profile/:userUniqueID" Component={ClientUserProfile}></Route>

          <Route exact path="tasks" Component={ClientTasks}></Route>

          {/* <Route exact path="subscription-plans" Component={ClientSubscriptionPlans}></Route>*/}
          <Route exact path="checkout" Component={ClientCheckout}></Route>

          <Route exact path="sms" Component={ClientSMS}></Route>
          <Route exact path="email" Component={ClientEmail}></Route>
        </Route>

        {/* Admin Routes */}
        <Route path="admin">
          <Route exact path="login" Component={AdminLogin}></Route>
          <Route exact path="email-verification" Component={AdminEmailVerification}></Route>
          <Route exact path="forgot-password" Component={AdminForgotPassword}></Route>
          <Route exact path="reset-password" Component={AdminResetPassword}></Route>

          <Route exact path="dashboard" Component={AdminDashboard}></Route>
          <Route exact path="calender" Component={AdminCalender}></Route>

          <Route exact path="jobs" Component={AdminJobs}></Route>
          <Route exact path="boosted-jobs" Component={AdminJobs}></Route>
          <Route exact path="job-post-step1" Component={AdminJobPostStep1}></Route>
          <Route exact path="job-post-step1/:jobID" Component={AdminJobPostStep1}></Route>
          <Route exact path="job-post-step2" Component={AdminJobPostStep2}></Route>
          <Route exact path="job-post-step2/:jobID" Component={AdminJobPostStep2}></Route>
          <Route exact path="job-post-step3" Component={AdminJobPostStep3}></Route>
          <Route exact path="job-post-step3/:jobID" Component={AdminJobPostStep3}></Route>
          <Route exact path="job-post-step4" Component={AdminJobPostStep4}></Route>
          <Route exact path="job-post-step4/:jobID" Component={AdminJobPostStep4}></Route>
          <Route exact path="job-post-step5" Component={AdminJobPostStep5}></Route>
          <Route exact path="job-post-step5/:jobID" Component={AdminJobPostStep5}></Route>
          <Route exact path="job/:jobId" Component={AdminJobDetail}></Route>
          <Route exact path="job-applications" Component={AdminJobApplocation}></Route>
          <Route exact path="search" Component={AdminSearchResume}></Route>

          <Route exact path="interviews" Component={AdminInterviewList}></Route>
          <Route exact path="follow-ups" Component={AdminInterviewList}></Route>
          <Route exact path="applicants" Component={AdminApplicantsList}></Route>

          <Route exact path="candidates" Component={AdminCandidatesList}></Route>
          <Route exact path="add-candidate-step1" Component={AdminAddCandidateStep1}></Route>
          <Route
            exact
            path="add-candidate-step1/:userID"
            Component={AdminAddCandidateStep1}
          ></Route>
          <Route exact path="add-candidate-step2" Component={AdminAddCandidateStep2}></Route>
          <Route exact path="add-candidate-step3" Component={AdminAddCandidateStep3}></Route>
          <Route exact path="add-candidate-step4" Component={AdminAddCandidateStep4}></Route>
          <Route exact path="add-candidate-step5" Component={AdminAddCandidateStep5}></Route>

          <Route exact path="employees" Component={AdminEmloyeesList}></Route>
          <Route exact path="add-employee-step1" Component={AdminAddEmployeeStep1}></Route>
          <Route exact path="add-employee-step1/:userID" Component={AdminAddEmployeeStep1}></Route>
          <Route exact path="add-employee-step2" Component={AdminAddEmployeeStep2}></Route>
          <Route exact path="add-employee-step3" Component={AdminAddEmployeeStep3}></Route>
          <Route exact path="add-employee-step4" Component={AdminAddEmployeeStep4}></Route>
          <Route exact path="add-employee-step5" Component={AdminAddEmployeeStep5}></Route>

          <Route exact path="agencies" Component={AdminAgenciesList}></Route>
          <Route exact path="agency-profile/:Id" Component={AdminAgenciesDetail}></Route>

          <Route exact path="facilities" Component={AdminEmloyers}></Route>
          <Route exact path="facility-profile/:Id" Component={AdminEmployerDetail}></Route>

          <Route exact path="office-admins" Component={AdminInterviewers}></Route>
          <Route exact path="compliance-files" Component={ManageCompliance}></Route>
          <Route exact path="compliance-file-categories" Component={ComplianceCategory}></Route>
          <Route exact path="compliance-file-types" Component={ComplianceTypes}></Route>
          <Route exact path="compliance-list" Component={FacilityComplianceLists}></Route>

          <Route exact path="compliance-checklists" Component={AdminComplianceChecklists}></Route>
          <Route
            exact
            path="submitted-checklists"
            Component={AdminComplianceSubmittedChecklists}
          ></Route>
          <Route exact path="add-checklist" Component={AdminComplianceAddChecklist}></Route>
          <Route
            exact
            path="add-checklist/:checklistID"
            Component={AdminComplianceAddChecklist}
          ></Route>
          <Route exact path="assign-checklist" Component={AdminComplianceAssignChecklist}></Route>

          <Route exact path="documents" Component={ComplianceDocument}></Route>
          <Route exact path="document-share-history" Component={AdminDocumentShareHistory}></Route>
          <Route exact path="document-types" Component={AdminiDocumentTypes}></Route>
          <Route exact path="tasks" Component={Tasks}></Route>
          <Route exact path="company-profile" Component={CompanyProfile}></Route>
          <Route exact path="sub-admins" Component={SubAdmin}></Route>
          <Route exact path="professions" Component={Professions}></Route>
          <Route exact path="specialities" Component={Specialities}></Route>
          <Route exact path="rec-comp-teams" Component={RecruitmentTeam}></Route>
          <Route exact path="reports-analytics" Component={ReportsAnalytics}></Route>
          <Route exact path="announcements" Component={Announcements}></Route>
          <Route exact path="skills" Component={Skills}></Route>
          <Route exact path="scheduling" Component={Scheduling}></Route>

          <Route exact path="clients" Component={Clients}></Route>
          <Route exact path="assignments" Component={Assignments}></Route>
          <Route exact path="submissions" Component={Submissions}></Route>
          <Route exact path="redirects" Component={Redirects}></Route>
          <Route exact path="user-profile/:userUniqueID" Component={UserProfile}></Route>

          <Route exact path="blogs" Component={AdminBlogs}></Route>

          <Route exact path="sms" Component={AdminSMS}></Route>
          <Route exact path="email" Component={AdminEmail}></Route>
          <Route exact path="activity-log" Component={ActivityLog}></Route>
          <Route exact path="contact-enquiries" Component={AdminContactEnquiries}></Route>
        </Route>
      </Routes>
    </>
  );
}

export default App;
