import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import PageTitle from "../../Layout/PageTitle";
import * as api from "../../../services/ApiService";
import { getTokenData } from "../../../utils/tokenUtils";
import { formatDate } from "../../../utils/dateFormat";
import Spinner from "../../Loader/Spinner";
import { Link } from "react-router-dom";

const ActivityLog = () => {
  const user = getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    start_date: "",
    end_date: "",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      start_date: "",
      end_date: "",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchActivityLog();
    }
  }, [showFilterDropdown])

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [roles, setRoles] = useState([]);
  const [selectedRole, setRole] = useState("User");
  const [activityLog, setActivityLog] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const fetchUserRoles = async () => {
    try {
      const param = { user_id: user.id };
      const apiData = await api.postMethod(param, "admin/get-user-roles");
      setRoles(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchActivityLog = async () => {
    setIsLoading(true);
    try {
      const param = { user_id: user.id, role: selectedRole };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-user-activities");
      setActivityLog(apiData.data);
      setIsLoading(false);
    } catch (error) {
      setIsLoading(false);
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserRoles();
  }, []);

  useEffect(() => {
    fetchActivityLog();
  }, [selectedRole]);

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Activity Log", link: null },
  ];
  return (
    <Layout>
      <PageTitle title="Activity Log" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-header border-bottom">
              <ul class="nav nav-pills nav-fill navtab-bg" role="tablist">
                {roles &&
                  roles.length > 0 &&
                  roles.map((role) => (
                    <li class="nav-item" role="presentation">
                      <Link
                        onClick={() => setRole(role?.role)}
                        class={selectedRole == role.role ? "nav-link active" : "nav-link"}
                      >
                        {role?.role}
                      </Link>
                    </li>
                  ))}
              </ul>
            </div>
            <div className="card-body">
              <div className="row justify-content-between mb-3">
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="start_date" className="form-label">
                            Date Range
                          </label>
                          <div className="input-group">
                            <input
                              type="date"
                              name="start_date"
                              className="form-control"
                              value={filters.start_date}
                              onChange={handleFilterInputChange}
                            />
                            <span className="input-group-text">To</span>
                            <input
                              type="date"
                              name="end_date"
                              className="form-control"
                              value={filters.end_date}
                              onChange={handleFilterInputChange}
                            />
                          </div>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                {/* end col*/}
              </div>
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th>Date</th>
                      <th>Candidate</th>
                      <th>Description</th>
                    </tr>
                  </thead>
                  <tbody>
                    {activityLog &&
                      activityLog.length > 0 &&
                      activityLog.map((activity) => (
                        <tr>
                          <td>{formatDate(activity?.created_at, "MMM DD, YYYY hh:mm A")}</td>
                          <td>
                            {activity.name ? (
                              <Link to={`/admin/user-profile/` + activity.unique_id} className="">
                                {activity.name}
                              </Link>
                            ) : (
                              "N/A"
                            )}
                          </td>
                          <td>{activity?.description}</td>
                        </tr>
                      ))}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>
      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default ActivityLog;
