import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { formatDate } from "../../../utils/dateFormat";

function JobDetail() {
  let navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);
  const { cId } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [profileInfo, setProfileInfo] = useState("");
  const [jobs, setJobs] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchUserDetails = async () => {
    try {
      const param = {
        user_id: user.id,
        userID: cId,
        role_id: 3,
      };
      const apiData = await api.postMethod(param, "user/get-company-details");

      if (apiData.status) {
        setProfileInfo(apiData.data.agency_details);

        setJobs(apiData.data.total_jobs);
        setLoading(false);
        setNoRecordsFound(false);
        if (apiData.data.total_jobs.length === 0) {
          setNoRecordsFound(true);
        }
      } else navigate("/user/jobs");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [cId]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item">Company Profile</li>
                        <li className="breadcrumb-item active">{profileInfo.company_name}</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Company Profile</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-xl-12">
                  <div className="card">
                    <div className="card-body custom-box-shadow">
                      <div className="d-flex align-items-start mb-3">
                        {profileInfo.profile_pic_path && (
                          <img
                            src={profileInfo.profile_pic_path}
                            className="d-flex me-3 rounded-circle avatar-lg"
                            alt={profileInfo.company_name}
                          />
                        )}
                        <div className="w-100">
                          <h3 className="my-0 font-20">{profileInfo.company_name}</h3>
                          <p className="text-muted mb-2">
                            Member Since:{" "}
                            {profileInfo.created_at &&
                              formatDate(profileInfo.created_at, "MMM DD, YYYY")}
                          </p>
                          {profileInfo.status == 1 && (
                            <label className="badge bg-success text-light mb-3">Active</label>
                          )}
                          {profileInfo.status == 0 && (
                            <label className="badge bg-secondary text-light mb-3">Draft</label>
                          )}
                          {profileInfo.status == 2 && (
                            <label className="badge bg-danger text-light mb-3">Blocked</label>
                          )}
                          {profileInfo.status == 3 && (
                            <label className="badge bg-info text-light mb-3">Terminate</label>
                          )}
                        </div>
                      </div>

                      <div className="text-start mt-3">
                        {profileInfo.bio && (
                          <>
                            <h4 className="font-13 text-capitalize fw-bold">
                              About {profileInfo.company_name} :{" "}
                            </h4>
                            <p className=" mb-3">{profileInfo.bio}</p>
                          </>
                        )}

                        <div className="row">
                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">Admin Name :</h4>
                            <p className="mb-3">{profileInfo.name || "-"}</p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">Email :</h4>
                            <p className="mb-3">{profileInfo.email || "-"}</p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">Phone :</h4>
                            <p className="mb-3">{profileInfo.phone || "-"}</p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">
                              Address Line 1 :
                            </h4>
                            <p className="mb-3">
                              {profileInfo.address_line1 ? profileInfo.address_line1 : "-"}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">
                              Address Line 2 :
                            </h4>
                            <p className="mb-3">
                              {profileInfo.address_line2 ? profileInfo.address_line2 : "-"}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">State :</h4>
                            <p className="mb-3">
                              {profileInfo.state_name ? profileInfo.state_name : "-"}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">City :</h4>
                            <p className="mb-3">
                              {profileInfo.city_name ? profileInfo.city_name : "-"}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">Website :</h4>
                            <p className="mb-3">
                              {profileInfo.website ? (
                                <Link to={profileInfo.website} target="_blank">
                                  {profileInfo.website}
                                </Link>
                              ) : (
                                "-"
                              )}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">
                              Primary Industry :
                            </h4>
                            <p className="mb-3">{profileInfo.primary_industry || "-"}</p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">Founded In :</h4>
                            <p className="mb-3">
                              {profileInfo.founded_in ? profileInfo.founded_in : "-"}
                            </p>
                          </div>

                          <div className="col-md-4">
                            <h4 className="font-13 text-capitalize fw-bold mb-1 ">
                              Company Size :
                            </h4>
                            <p className="mb-3">
                              {profileInfo.company_size ? profileInfo.company_size : "-"}
                            </p>
                          </div>

                          {profileInfo.social_media_links && (
                            <div className="col-md-4">
                              <h4 className="font-13 text-capitalize fw-bold mb-1 ">
                                Social Media Links :
                              </h4>
                              {profileInfo.linkedin_url && (
                                <>
                                  <Link
                                    to={profileInfo.linkedin_url}
                                    target="_blank"
                                    className="social-list-item border-secondary text-secondary"
                                    data-tooltip-id="mt-1"
                                    data-tooltip-content="Linkedin"
                                  >
                                    <Tooltip id="mt-1"></Tooltip>
                                    <i className="mdi mdi-linkedin  font-20"></i>
                                  </Link>
                                </>
                              )}
                              {profileInfo.instagram_url && (
                                <>
                                  <Link
                                    to={profileInfo.instagram_url}
                                    target="_blank"
                                    className="social-list-item border-secondary text-secondary"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Instagram"
                                  >
                                    <Tooltip id="mt-2"></Tooltip>
                                    <i className="mdi mdi-instagram font-20"></i>
                                  </Link>
                                </>
                              )}
                              {profileInfo.twitter_url && (
                                <>
                                  <Link
                                    to={profileInfo.twitter_url}
                                    target="_blank"
                                    className="social-list-item border-secondary text-secondary "
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Twitter"
                                  >
                                    <Tooltip id="mt-3"></Tooltip>
                                    <i className="mdi mdi-twitter font-20"></i>
                                  </Link>
                                </>
                              )}
                              {profileInfo.facebook_url && (
                                <>
                                  <Link
                                    to={profileInfo.facebook_url}
                                    target="_blank"
                                    className="social-list-item border-secondary text-secondary "
                                    data-tooltip-id="mt-4"
                                    data-tooltip-content="Facebook"
                                  >
                                    <Tooltip id="mt-4"></Tooltip>
                                    <i className="mdi mdi-facebook font-20"></i>
                                  </Link>
                                </>
                              )}
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="row">
                <div className="col-xl-12">
                  <div className="card custom-box-shadow">
                    <div className="card-header border-bottom">
                      <h5 className="card-title mb-0">Job Listings</h5>
                    </div>
                    <div className="card-body">
                      <div className="table-responsive">
                        <table className="table table-centered mb-0">
                          {/* table-nowrap */}
                          <thead className="table-light">
                            <tr>
                              <th width="30%">Job Title</th>
                              <th>Specialty</th>
                              <th>Status</th>
                              <th>Date Posted</th>
                            </tr>
                          </thead>
                          <tbody>
                            {loading ? (
                              <tr>
                                <td colSpan="4">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noRecordsFound ? (
                                  <tr>
                                    <td colSpan="4">No records found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(jobs).map(([key, value]) => (
                                      <tr key={value.id}>
                                        <td>
                                          <Link
                                            to={`/user/job/` + value.unique_id}
                                            className="job-title"
                                            data-tooltip-id={`mt-` + value.unique_id}
                                            data-tooltip-content={value.title}
                                          >
                                            {value.title}
                                          </Link>
                                          <Tooltip id={`mt-` + value.unique_id}></Tooltip>

                                          <p className="mb-0 text-muted">
                                            <small>(#{value.unique_id})</small>
                                          </p>
                                          {(value.state_name !== null ||
                                            value.city_name !== null) && (
                                            <p className="mb-0 ">
                                              <small>
                                                <i className="fa fa-map-marker-alt me-1"></i>
                                                {value.state_name !== null &&
                                                value.city_name !== null
                                                  ? value.state_name + ", " + value.city_name
                                                  : value.state_name !== null
                                                  ? value.state_name
                                                  : value.city_name !== null
                                                  ? value.state_name
                                                  : ""}
                                              </small>
                                            </p>
                                          )}
                                        </td>
                                        <td>{value.specialty !== null ? value.specialty : "-"}</td>
                                        <td>
                                          <h5>
                                            {value.status == 1 && (
                                              <label className="badge badge-soft-success">
                                                Active
                                              </label>
                                            )}
                                            {value.status == 0 && (
                                              <label className="badge badge-soft-secondary">
                                                Draft
                                              </label>
                                            )}
                                            {value.status == 6 && (
                                              <label className="badge badge-soft-danger">
                                                Expired
                                              </label>
                                            )}
                                          </h5>
                                        </td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>
          {/* content */}

          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default JobDetail;
