import React, { useState, useEffect } from "react";
import { useParams, Link, useNavigate, useLocation } from "react-router-dom";
import Layout from "../../../Layout/Index";
import PageTitle from "../../../Layout/PageTitle";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as api from "../../../../services/ApiService";
import PersonalInfo from "./Tabs/PersonalInfo";
import ProfessionalInfo from "./Tabs/ProfessionalInfo";
import Reference from "./Tabs/Reference";
/*
import EducationalInfo from "./Tabs/EducationalInfo";
*/
import ComplianceFiles from "./Tabs/ComplianceFiles";
import JobApplications from "./Tabs/JobApplications";
import Connections from "./Tabs/Connections";
import SMS from "./Tabs/SMS";
import Messages from "./Tabs/Messages";
import Checklists from "./Tabs/Checklists";
import { formatDate } from "../../../../utils/dateFormat";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const UserProfile = () => {
  let user = tokenUtils.getTokenData();
  const { userUniqueID } = useParams();
  let navigate = useNavigate();
  const location = useLocation();

  const [selectedTab, setSelectedTab] = useState("personal_info");
  // Tab select event
  const handleTabSelect = (tab) => {
    setSelectedTab(tab);
  };

  const [profileInfo, setProfileInfo] = useState("");

  const fetchUserDetails = async () => {
    try {
      const param = {
        step: "step1",
        user_id: user.id,
        token: user.token,
        userID: userUniqueID,
      };
      const apiData = await api.postMethod(param, "admin/get-user-details");

      if (apiData.status) {
        setProfileInfo(apiData.data);

        if (location.state?.tab) {
          setSelectedTab(location.state?.tab);
        }
      } //else navigate("/admin/add-user-step2");
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userUniqueID]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  /* SMS Modal Start */
  const [showSMSModal, setShowSMSModal] = useState(false);
  const [isSMSLoading, setIsSMSLoading] = useState(false);
  const [smsFormData, setSMSFormData] = useState({
    id: null,
    from_id: user.id,
    to_id: 0,
    type: "sms",
    message: "",
  });

  useEffect(() => {
    if (profileInfo.id) {
      setSMSFormData((prevState) => ({
        ...prevState,
        to_id: profileInfo.id,
      }));
    }
  }, [profileInfo]);

  const toggleSMSModal = () => {
    setShowSMSModal(!showSMSModal);

    if (!showSMSModal) {
      setSMSFormData({
        id: null,
        from_id: user.id,
        /*to_id: profileInfo.id ? profileInfo.id : 0,*/
        type: "sms",
        message: "",
      });

      // Reset validation errors
      setSMSErrors({});
    }
  };

  //Define the validation schema
  const validationSMSSchema = Yup.object({
    message: Yup.string().required("Message is Required"),
  });

  const [smsErrors, setSMSErrors] = useState({});
  const handleSMSSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSMSSchema.validate(smsFormData, { abortEarly: false });

      setIsSMSLoading(true);
      const result = await api.postFileMethod(smsFormData, "client/send-message");
      setIsSMSLoading(false);
      if (result.status) {
        toast.success(result.message);
        setShowSMSModal(false);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsSMSLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setSMSErrors(newErrors);
    }
  };

  const handleSMSChange = (e) => {
    const { name, value } = e.target;
    setSMSFormData({ ...smsFormData, [name]: value });

    validateSMSFields(name, value);
  };

  const validateSMSFields = (name, value) => {
    try {
      validationSMSSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setSMSErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setSMSErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };
  /* SMS Modal End */

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "User Profile", link: null },
  ];

  return (
    <Layout>
      {/* start page title */}
      <PageTitle title={"User Profile"} breadcrumbItems={breadcrumbItems} />
      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row">
                <div className="col-md-9">
                  <div className="d-flex align-items-start mb-3">
                    {profileInfo.profile_pic_path && (
                      <img
                        src={profileInfo.profile_pic_path}
                        className="d-flex me-3 rounded-circle avatar-lg"
                        alt={profileInfo.name}
                        height={32}
                      />
                    )}
                    <div className="w-100">
                      <h4 className="mt-0 mb-1">
                        {profileInfo.name} (#{profileInfo.unique_id})
                      </h4>
                      <p className="text-muted">
                        <span className="text-dark fw-bold">Member Since</span> :{" "}
                        {profileInfo.created_at &&
                          formatDate(profileInfo.created_at, "MMM DD, YYYY")}
                      </p>
                      <h4>
                        {profileInfo.status == 0 && (
                          <label className="badge badge-outline-warning badge-pill">
                            In-Active
                          </label>
                        )}
                        {profileInfo.status == 1 && (
                          <label className="badge badge-outline-success badge-pill">Active</label>
                        )}
                        {profileInfo.status == 2 && (
                          <label className="badge badge-outline-danger badge-pill">Blocked</label>
                        )}
                        {profileInfo.status == 3 && (
                          <label className="badge badge-outline-info badge-pill">Terminate</label>
                        )}
                      </h4>
                    </div>
                  </div>
                </div>
                <div className="col-md-3">
                  <div className="text-sm-end">
                    <Link
                      to={
                        `/admin/add-${profileInfo.role_id == 9 ? "employee" : "candidate"}-step1/` +
                        profileInfo.unique_id
                      }
                      className="btn btn-primary waves-effect waves-light mb-2 btn-sm me-1"
                    >
                      <i className="fas fa-edit" /> Edit Profile
                    </Link>

                    <button
                      type="button"
                      className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                      onClick={() => setShowSMSModal(true)}
                    >
                      <i className="fas fa-mobile" /> SMS
                    </button>

                    {/*         
                    <button
                              type="button"
                              className="btn btn-info waves-effect waves-light mb-2 btn-sm me-1"
                              onClick={() => setShowMessageModal(true)}
                            >
                              <i className="fas fa-envelope" /> Message
                            </button>
                    */}
                  </div>
                </div>
              </div>

              <div className="row mb-1 row-cols-4">
                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " +
                      (selectedTab === "personal_info" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("personal_info")}
                  >
                    Personal Info
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " +
                      (selectedTab === "professional_info" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("professional_info")}
                  >
                    Professional Info
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "references" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("references")}
                  >
                    References
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " +
                      (selectedTab === "job_applications" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("job_applications")}
                  >
                    Job Applications
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "connections" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("connections")}
                  >
                    Connections
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "checklists" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("checklists")}
                  >
                    Checklists
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "sms" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("sms")}
                  >
                    SMS
                  </button>
                </div>

                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " +
                      (selectedTab === "compliance-files" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("compliance-files")}
                  >
                    Compliance Files
                  </button>
                </div>

                {/* 
                <div className="col mb-2">
                  <button
                    type="button"
                    className={
                      "btn  w-100 " + (selectedTab === "messages" ? "btn-primary" : "btn-light")
                    }
                    onClick={() => handleTabSelect("messages")}
                  >
                    Messages
                  </button>
                </div>

                */}
              </div>

              {selectedTab === "personal_info" && <PersonalInfo profileInfo={profileInfo} />}

              {selectedTab === "professional_info" && (
                <ProfessionalInfo profileInfo={profileInfo} />
              )}

              {selectedTab === "job_applications" && <JobApplications profileInfo={profileInfo} />}

              {selectedTab === "connections" && <Connections profileInfo={profileInfo} />}

              {selectedTab === "sms" && <SMS profileInfo={profileInfo} />}

              {selectedTab === "messages" && <Messages profileInfo={profileInfo} />}

              {selectedTab === "checklists" && <Checklists profileInfo={profileInfo} />}

              {selectedTab === "references" && <Reference profileInfo={profileInfo} />}

              {selectedTab === "compliance-files" && <ComplianceFiles profileInfo={profileInfo} />}
            </div>
          </div>

          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={showSMSModal} onHide={toggleSMSModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSMSSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Send SMS</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-0 col-md-12">
                <label className="form-label" htmlFor="message">
                  Message
                </label>
                <textarea
                  className="form-control"
                  onChange={handleSMSChange}
                  name="message"
                  id="message"
                  value={smsFormData.message}
                  rows={5}
                />
                {smsErrors.message && <span className="error">{smsErrors.message}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleSMSModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </Layout>
  );
};

export default UserProfile;
