import React, { useState, useEffect } from "react";
import Header from "../Layout/Header";
import Footer from "../Layout/Footer";
import Sidebar from "../Layout/Sidebar";
import ThemeSettings from "../Layout/ThemeSettings";
import { Link } from "react-router-dom";
import * as api from "../../services/ApiService";
import * as tokenUtils from "../../utils/tokenUtils";
import { toast } from "react-toastify";

function Settings() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  // Initialize state for settings
  const [settings, setSettings] = useState({
    user_id: user.id,
    token: user.token,
    smsNotifications: 1, // Current setting, more can be added later
  });

  // Fetch settings from API on page load
  useEffect(() => {
    const fetchSettings = async () => {
      try {
        const param = {
          user_id: user.id,
          token: user.token,
        };
        const response = await api.postMethod(param, "user/get-settings"); // Replace with your API endpoint
        setSettings(response.data);
      } catch (error) {
        console.error("Error fetching settings:", error);
      }
    };

    fetchSettings();
  }, []);

  // Handle button click
  const handleButtonClick = async (settingKey, value) => {
    const updatedSettings = {
      ...settings,
      [settingKey]: value,
      user_id: user.id, // Ensure user_id is included
      token: user.token,
    };

    // Update state
    setSettings(updatedSettings);

    // Optionally, call API to save the updated setting
    await api
      .postMethod(updatedSettings, "user/update-settings") // Replace with your API endpoint
      .then((response) => {
        toast.success(response.message);
      })
      .catch((error) => {
        console.error("Error updating settings:", error);
      });
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <div className="row">
                <div className="col-12">
                  <div className="page-title-box">
                    <div className="page-title-right">
                      <ol className="breadcrumb m-0">
                        <li className="breadcrumb-item">
                          <Link to="/dashboard">Home</Link>
                        </li>
                        <li className="breadcrumb-item active">Settings</li>
                      </ol>
                    </div>
                    <h4 className="page-title">Settings</h4>
                  </div>
                </div>
              </div>
              {/* end page title */}
              <div className="row">
                <div className="col-lg-12 col-xl-12">
                  <div className="card">
                    <div className="card-body">
                      <div className="row">
                        <div className="col-md-3">
                          <div className="mb-0">
                            <label htmlFor="name" className="form-label">
                              Allow SMS Notifications
                            </label>
                            <br />
                            <div class="btn-group mb-0">
                              <button
                                type="button"
                                className={`btn ${
                                  settings.smsNotifications === 1 ? "btn-primary" : "btn-light"
                                }`}
                                onClick={() => handleButtonClick("smsNotifications", 1)}
                              >
                                On
                              </button>
                              <button
                                type="button"
                                className={`btn ${
                                  settings.smsNotifications === 0 ? "btn-primary" : "btn-light"
                                }`}
                                onClick={() => handleButtonClick("smsNotifications", 0)}
                              >
                                Off
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>

      <ThemeSettings />
    </>
  );
}

export default Settings;
