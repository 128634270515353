import { React } from "react";
import { Link } from "react-router-dom";

function Logo() {
  return (
    <div className="auth-brand text-center text-lg-start">
      <div className="auth-brand">
        <Link to="./" className="logo logo-dark text-center">
          <span className="logo-lg">
            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="logo" width={160} />
          </span>
        </Link>
        <Link to="./" className="logo logo-light text-center">
          <span className="logo-lg">
            <img src={process.env.PUBLIC_URL + "/assets/images/logo.png"} alt="logo" width={160} />
          </span>
        </Link>
      </div>
    </div>
  );
}

export default Logo;
