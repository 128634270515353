import React, { useState, useEffect } from "react";
import Header from "../../Layout/Header";
import Footer from "../../Layout/Footer";
import Sidebar from "../../Layout/Sidebar";
import ThemeSettings from "../../Layout/ThemeSettings";
import { Link, NavLink } from "react-router-dom";
import { Tooltip } from "react-tooltip";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import * as Yup from "yup";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import Dropdown from "react-bootstrap/Dropdown";
import PageTitle from "../../Layout/PageTitle";
import { currentRole } from "../../Layout/HelmetComponent";
import { formatDate } from "../../../utils/dateFormat";
import useConfirm from "../../../hooks/useConfirm";

function AppliedJobs() {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [isLoading, setIsLoading] = useState(false);

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchAppliedJobs();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [appliedJobs, setAppliedJob] = useState({});
  const [appliedJobLoading, setAppliedJobLoading] = useState(true);
  const [noAppliedJobRecordsFound, setNoAppliedJobRecordsFound] = useState(false);

  const fetchAppliedJobs = async () => {
    try {
      const param = {
        user_id: user.id,
      };

      const queryParams = { ...param, ...filters };
      const result = await api.postMethod(queryParams, "client/get-job-applications");
      if (result.status) {
        setAppliedJob(result.data);

        setAppliedJobLoading(false);
        // Check if data array is empty
        if (result.data.length === 0) {
          setNoAppliedJobRecordsFound(true);
        } else setNoAppliedJobRecordsFound(false);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setAppliedJobLoading(false);
    }
  };

  useEffect(() => {
    fetchAppliedJobs();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts
  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      setIsLoading(true);
      record.user_id = user.id;
      const result = await api.postMethod(record, "client/delete-job-application");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchAppliedJobs();
      }
    }
  };

  const [jobAppStatus, setJobAppStatus] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchJobAppStatus = async () => {
      try {
        const apiData = await api.getMethod("get-job-application-status");
        setJobAppStatus(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchJobAppStatus();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  /* Select All checkbox functions start */

  const [selectedJobIds, setSelectedJobIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = appliedJobs.map((record) => record.job_application_id);
      setSelectedJobIds(allUserIds);
    } else {
      setSelectedJobIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedJobIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedJobIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          job_ids: selectedJobIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "client/job-application-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedJobIds([]);
          setBulkAction("");
          fetchAppliedJobs();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }

    /*
    if (bulkAction === "delete") {
      
      axios
        .post("/api/delete-users", { user_ids: selectedJobIds })
        .then((response) => {
          setRecords(records.filter((record) => !selectedJobIds.includes(record.id)));
          setSelectedJobIds([]);
        })
        .catch((error) => console.error("There was an error deleting the users!", error));
      
    } else if (bulkAction === "change-status") {
      
      axios.post('/api/change-status', { user_ids: selectedJobIds, status: 'inactive' })
        .then(response => {
          setRecords(records.map(record => 
            selectedJobIds.includes(record.id) ? { ...record, status: 'inactive' } : record
          ));
          setSelectedJobIds([]);
        })
        .catch(error => console.error('There was an error changing the status!', error));
        
    }
    */
  };

  const breadcrumbItems = [
    { text: currentRole + " Dashboard", link: "/client/dashboard" },
    { text: "Jobs", link: null },
  ];

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />
          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              <PageTitle title="Job Applications" breadcrumbItems={breadcrumbItems} />
              {/* end page title */}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <div className="row justify-content-between mb-3">
                        <div className="col-md-6">
                          <div className="">
                            <select
                              className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                              value={bulkAction}
                              onChange={handleBulkActionChange}
                            >
                              <option value="">Bulk Actions</option>
                              <option value="delete">Delete</option>
                            </select>
                            <button onClick={handleBulkActionSubmit} className="btn btn-info">
                              Submit
                            </button>
                          </div>
                        </div>
                        <div className="col-auto">
                          <div className="dropdown" style={{ width: 300 }}>
                            <button
                              type="button"
                              className="btn btn-outline-light dropdown-toggle w-100 text-start"
                              onClick={handleToggleFilterDropDown}
                            >
                              Filters
                              <i className="mdi mdi-chevron-down float-end" />
                            </button>
                            <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                              <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                                <div className="mb-2">
                                  <label htmlFor="keyword" className="form-label">
                                    Keyword
                                  </label>
                                  <input
                                    type="text"
                                    className="form-control"
                                    id="keyword"
                                    name="keyword"
                                    value={filters.keyword}
                                    onChange={handleFilterInputChange}
                                  />
                                </div>
                                <div className="mb-2">
                                  <label htmlFor="status" className="form-label">
                                    Status
                                  </label>
                                  <select
                                    className="form-control"
                                    id="job_status"
                                    name="status"
                                    value={filters.status}
                                    onChange={handleFilterInputChange}
                                  >
                                    <option value="all">Please Select</option>
                                    {jobAppStatus.map((status) => (
                                      <>
                                        <option value={status.id} key={status.id}>
                                          {status.title}
                                        </option>
                                      </>
                                    ))}
                                  </select>
                                </div>
                                <div className="text-end">
                                  <button
                                    type="button"
                                    className="btn btn-light float-start"
                                    onClick={handleReset}
                                  >
                                    Reset
                                  </button>
                                  <button type="submit" className="btn btn-primary">
                                    Submit
                                  </button>
                                </div>
                              </form>
                            </div>
                          </div>
                        </div>
                        <div className="col-auto" />
                        {/* end col*/}
                      </div>
                      <div className="table-responsive">
                        <table className="table table-centered table-nowrap mb-0">
                          <thead className="table-light">
                            <tr>
                              <th className="form-checks">
                                <input
                                  type="checkbox"
                                  onChange={handleSelectAllChange}
                                  checked={
                                    selectedJobIds.length === appliedJobs.length &&
                                    appliedJobs.length > 0
                                  }
                                  className="form-check-input"
                                />
                              </th>
                              <th>Applicant</th>
                              <th width="25%">Job Title</th>
                              <th>Profile Match</th>
                              <th>Status</th>
                              <th>Applied On</th>
                              <th>Action</th>
                            </tr>
                          </thead>
                          <tbody>
                            {appliedJobLoading ? (
                              <tr key="0">
                                <td colSpan="7">Loading...</td>
                              </tr>
                            ) : (
                              <>
                                {noAppliedJobRecordsFound ? (
                                  <tr key="0">
                                    <td colSpan="7">No job applicants found</td>
                                  </tr>
                                ) : (
                                  <>
                                    {Object.entries(appliedJobs).map(([key, value]) => (
                                      <tr key={value.job_application_id}>
                                        <td className="form-checks">
                                          <input
                                            type="checkbox"
                                            value={value.job_application_id}
                                            name="job_ids"
                                            onChange={handleCheckboxChange}
                                            checked={selectedJobIds.includes(
                                              value.job_application_id
                                            )}
                                            className="form-check-input"
                                          />
                                        </td>
                                        <td>
                                          <Link
                                            to={`/client/user-profile/` + value.user_unique_id}
                                            className=""
                                          >
                                            {value.name}
                                          </Link>
                                        </td>
                                        <td>
                                          <Link
                                            to={`/client/job/` + value.unique_id}
                                            className="job-title"
                                            data-tooltip-id={`mt-` + value.unique_id}
                                            data-tooltip-content={value.title}
                                          >
                                            {value.title}
                                          </Link>
                                          <Tooltip id={`mt-` + value.unique_id}></Tooltip>
                                          <p className="mb-0 text-muted">
                                            <small>(#{value.unique_id})</small>
                                          </p>
                                          {(value.state_name !== null ||
                                            value.city_name !== null) && (
                                            <p className="mb-0">
                                              <small>
                                                <i className="fa fa-map-marker-alt me-1"></i>
                                                {value.state_name !== null &&
                                                value.city_name !== null
                                                  ? value.state_name + ", " + value.city_name
                                                  : value.state_name !== null
                                                  ? value.state_name
                                                  : value.city_name !== null
                                                  ? value.state_name
                                                  : ""}
                                              </small>
                                            </p>
                                          )}
                                        </td>
                                        <td>
                                          <div className="row align-items-center g-0">
                                            <div className="col-auto">
                                              <span className="me-2">
                                                {value.match_percentage > 99
                                                  ? 100
                                                  : Math.round(value.match_percentage)}
                                                %
                                              </span>
                                            </div>
                                            <div className="col">
                                              <div className="progress progress-sm">
                                                <div
                                                  className={
                                                    value.match_percentage > 75
                                                      ? "progress-bar bg-success"
                                                      : value.match_percentage > 60
                                                      ? "progress-bar bg-warning"
                                                      : value.match_percentage > 45
                                                      ? "progress-bar bg-danger"
                                                      : "progress-bar bg-danger"
                                                  }
                                                  role="progressbar"
                                                  style={{
                                                    width:
                                                      value.match_percentage > 99
                                                        ? "100%"
                                                        : Math.round(value.match_percentage) + "%",
                                                  }}
                                                  aria-valuenow={
                                                    value.match_percentage > 99
                                                      ? 100
                                                      : Math.round(value.match_percentage)
                                                  }
                                                  aria-valuemin={0}
                                                  aria-valuemax={100}
                                                />
                                              </div>
                                            </div>
                                          </div>
                                        </td>
                                        <td>
                                          {value.status == 1 && (
                                            <label className="badge badge-soft-primary">
                                              Applied
                                            </label>
                                          )}
                                          {value.status == 2 && (
                                            <label className="badge badge-soft-info">
                                              Shortlisted
                                            </label>
                                          )}
                                          {value.status == 3 && (
                                            <label className="badge badge-soft-warning">
                                              Submitted
                                            </label>
                                          )}
                                          {value.status == 4 && (
                                            <label className="badge badge-soft-warning">
                                              Interviewing
                                            </label>
                                          )}
                                          {value.status == 5 && (
                                            <label className="badge badge-soft-secondary">
                                              Offered
                                            </label>
                                          )}
                                          {value.status == 6 && (
                                            <label className="badge badge-soft-success">
                                              Hired
                                            </label>
                                          )}
                                          {value.status == 7 && (
                                            <label className="badge badge-soft-danger">
                                              Rejected
                                            </label>
                                          )}
                                        </td>
                                        <td>
                                          {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                          <small className="text-muted">
                                            {formatDate(value.created_at, "hh:mm A")}
                                          </small>
                                        </td>
                                        <td>
                                          <Dropdown className="" key={`dropdown-` + value.job_id}>
                                            <Dropdown.Toggle
                                              variant="default"
                                              id={`dropdown-basic-` + value.job_id}
                                              key={`dropdown-basic-` + value.job_id}
                                              className="p-0"
                                            >
                                              <i className="mdi mdi-dots-horizontal m-0 text-muted h3" />
                                            </Dropdown.Toggle>

                                            <Dropdown.Menu>
                                              <Dropdown.Item
                                                as={NavLink}
                                                to={`/client/job/` + value.unique_id}
                                              >
                                                View Job
                                              </Dropdown.Item>

                                              <Dropdown.Item onClick={() => handleDelete(value)}>
                                                Delete Job Application
                                              </Dropdown.Item>

                                              {/*
                                              <Dropdown.Divider />
                                              <Dropdown.Header>Change Status to</Dropdown.Header>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleUserApplicationStatus(
                                                    value.job_id,
                                                    value.user_id,
                                                    4
                                                  )
                                                }
                                              >
                                                Offered
                                              </Dropdown.Item>
                                              <Dropdown.Item
                                                onClick={() =>
                                                  handleUserApplicationStatus(
                                                    value.job_id,
                                                    value.user_id,
                                                    6
                                                  )
                                                }
                                              >
                                                Rejected
                                              </Dropdown.Item>
                                                 */}
                                            </Dropdown.Menu>
                                          </Dropdown>
                                        </td>
                                      </tr>
                                    ))}
                                  </>
                                )}
                              </>
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>
          <Footer />
        </div>
      </div>

      <ThemeSettings />
      {isLoading === true && <Spinner />}
    </>
  );
}

export default AppliedJobs;
