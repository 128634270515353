import React, { useState, useEffect } from "react";
import Layout from "../../Layout/Index";
import { Link } from "react-router-dom";
import * as api from "../../../services/ApiService";
import { Tooltip } from "react-tooltip";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";
import { formatDate } from "../../../utils/dateFormat";
import PageTitle from "../../Layout/PageTitle";
import useConfirm from "../../../hooks/useConfirm";

const RecruitmentTeam = () => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchComplianceFiles();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [complianceFiles, setComplianceFiles] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchComplianceFiles = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-recruitment-compliance-teams");

      setComplianceFiles(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      } else setNoRecordsFound(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchComplianceFiles();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    team_type: null,
    name: null,
    email: null,
    phone: null,
    status: null,
  });

  const [uploadModalOpen, setUploadModalOpen] = useState(false);

  const toggleUploadModal = () => {
    setUploadModalOpen(!uploadModalOpen);

    if (!uploadModalOpen) {
      setformData({
        id: null,
        user_id: user.id,
        team_type: null,
        name: null,
        email: null,
        phone: null,
        status: null,
      });

      // Reset validation errors
      setErrors({});
    }
  };

  const handleEdit = (record) => {
    // Populate formData with the values of the selected record
    setformData({
      user_id: user.id,
      id: record.id,
      team_type: record.team_type,
      name: record.name,
      email: record.email,
      phone: record.phone,
      status: record.status,
    });

    setUploadModalOpen(true);
  };

  const { confirm } = useConfirm();
  const handleDelete = async (record) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      record.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(record, "admin/delete-recruitment-compliance-team");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      }
    }
  };

  //Define the validation schema
  const validationSchema = Yup.object({
    team_type: Yup.string().required("Team Type is Required"),
    name: Yup.string().required("Name is Required"),
    email: Yup.string().required("Email is Required"),
  });

  const [errors, setErrors] = useState({});
  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "admin/update-recruitment-compliance-team");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        setUploadModalOpen(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = complianceFiles.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(
          param,
          "admin/recruitment-compliance-team-bulk-actions"
        );
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchComplianceFiles();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(
        param,
        "admin/update-recruitment-compliance-team-status"
      );
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Recruitment/Compliance Teams", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Recruitment/Compliance Teams" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="delete">Delete</option>
                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>In-Active</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={toggleUploadModal}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Team
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>

              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === complianceFiles.length &&
                            complianceFiles.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Team Type</th>
                      <th>Name</th>
                      <th>Email</th>
                      <th>Mobile No.</th>
                      <th>Creation Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="8">Loading...</td>
                      </tr>
                    ) : (
                      <>
                        {noRecordsFound ? (
                          <tr>
                            <td colSpan="8">No records found</td>
                          </tr>
                        ) : (
                          <>
                            {Object.entries(complianceFiles).map(([key, value]) => (
                              <tr key={value.id}>
                                <td className="form-checks">
                                  <input
                                    type="checkbox"
                                    value={value.id}
                                    name="user_ids"
                                    onChange={handleCheckboxChange}
                                    checked={selectedUserIds.includes(value.id)}
                                    className="form-check-input"
                                  />
                                </td>
                                <td>{value.team_type}</td>
                                <td>{value.name}</td>
                                <td>{value.email || "-"}</td>
                                <td>{value.phone || "-"}</td>
                                <td>
                                  {formatDate(value.created_at, "MMM DD, YYYY")}{" "}
                                  <small className="text-muted">
                                    {formatDate(value.created_at, "hh:mm A")}
                                  </small>
                                </td>
                                <td>
                                  {value.status == 1 && (
                                    <label className="badge badge-soft-success">Active</label>
                                  )}
                                  {value.status == 0 && (
                                    <label className="badge badge-soft-warning">In-Active</label>
                                  )}
                                  {value.status == 2 && (
                                    <label className="badge badge-soft-danger">Blocked</label>
                                  )}
                                  {value.status == 3 && (
                                    <label className="badge badge-soft-info">Terminate</label>
                                  )}
                                </td>

                                <td>
                                  {value.status == 0 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="In-Active"
                                        onClick={() => updateStatus(value.id, 1)}
                                      >
                                        <i className="mdi mdi-close-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}
                                  {value.status == 1 && (
                                    <>
                                      <button
                                        type="button"
                                        className="btn btn-default action-icon"
                                        data-tooltip-id="mt-1"
                                        data-tooltip-content="Active"
                                        onClick={() => updateStatus(value.id, 0)}
                                      >
                                        <i className="mdi mdi-check-box-outline" />
                                      </button>
                                      <Tooltip id="mt-1"></Tooltip>
                                    </>
                                  )}

                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-3"
                                    data-tooltip-content="Edit Recruitment/Compliance Team"
                                    onClick={() => handleEdit(value)}
                                  >
                                    <i className="mdi mdi-pencil" />
                                  </button>
                                  <Tooltip id="mt-3"></Tooltip>
                                  <button
                                    type="button"
                                    className="btn btn-default action-icon"
                                    data-tooltip-id="mt-2"
                                    data-tooltip-content="Remove Recruitment/Compliance Team"
                                    onClick={() => handleDelete(value)}
                                  >
                                    <i className="mdi mdi-delete" />
                                  </button>
                                  <Tooltip id="mt-2"></Tooltip>
                                </td>
                              </tr>
                            ))}
                          </>
                        )}
                      </>
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="md">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Recruitment/Compliance Team</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="team_type">
                  Team Type *
                </label>
                <select
                  className="form-control"
                  id="team_type"
                  name="team_type"
                  onChange={handleChange}
                  value={formData.team_type}
                >
                  <option value="" key="0">
                    Select Any Team Type
                  </option>
                  <option value="Recruitment Team" key="Recruitment Team">
                    Recruitment Team
                  </option>
                  <option value="Compliance Team" key="Compliance Team">
                    Compliance Team
                  </option>
                  <option value="Account Management Team" key="Account Management Team">
                    Account Management Team
                  </option>
                </select>
                {errors.team_type && <span className="error">{errors.team_type}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="name">
                  Name *
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="name"
                  name="name"
                  onChange={handleChange}
                  defaultValue={formData.name !== null ? formData.name : ""}
                />
                {errors.name && <span className="error">{errors.name}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="email">
                  Email *
                </label>
                <input
                  type="email"
                  className="form-control"
                  id="email"
                  name="email"
                  onChange={handleChange}
                  defaultValue={formData.email !== null ? formData.email : ""}
                />
                {errors.email && <span className="error">{errors.email}</span>}
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="phone">
                  Mobile Number
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="phone"
                  name="phone"
                  onChange={handleChange}
                  defaultValue={formData.phone !== null ? formData.phone : ""}
                />
              </div>

              <div className="col-md-12 mb-2">
                <label className="form-label" htmlFor="fstatus">
                  Status
                </label>
                <select
                  className="form-control"
                  id="fstatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : "1"}
                >
                  <option key="1" value="1">
                    Active
                  </option>
                  <option key="0" value="0">
                    In-Active
                  </option>
                </select>
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default RecruitmentTeam;
