import React, { useState, useEffect } from "react";
import * as api from "../../../services/ApiService";
import * as tokenUtils from "../../../utils/tokenUtils";
import * as Yup from "yup";
import { toast } from "react-toastify";
import Modal from "react-bootstrap/Modal";

const AssignmentModal = ({
  uploadModalOpen,
  toggleUploadModal,
  editAssignmentData,
  setIsLoading,
  fetchComplianceFiles,
  resetFormDataAndErrorsRef,
}) => {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [UserLists, setUserLists] = useState([]);
  const fetchUserLists = async () => {
    try {
      const apiData = await api.getMethod("get-users/5~" + user.id);

      setUserLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserLists();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [facilityLists, setFacilityLists] = useState([]);
  const fetchFacilities = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "client/get-clients");

      setFacilityLists(apiData.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchFacilities();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [formData, setformData] = useState({
    id: null,
    user_id: user.id,
    user_role_id: user.role_id,
    assigned_user_id: null,
    facility_id: user.role_id == 2 ? user.id : null,
    state_id: null,
    city_id: null,
    assigned_unit: null,
    start_date: null,
    end_date: null,
    assignment_length: null,
    shift: null,
    approved_time_off: null,
    confirmed_with_facility: 0,
    confirmed_with_traveler: 0,
    status: null,
  });

  useEffect(() => {
    if (editAssignmentData) {
      setformData({
        ...formData,
        ...editAssignmentData,
        user_id: user.id,
        user_role_id: user.role_id,
      });
    }
  }, [editAssignmentData]);

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    assigned_user_id: Yup.string().required("Candidate is Required"),
    facility_id:
      user.role_id == 2
        ? Yup.string().notRequired()
        : Yup.string().required("Facility is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
  });

  const [errors, setErrors] = useState({});

  // Function to reset formData and errors
  const resetFormDataAndErrors = () => {
    setformData({
      id: null,
      user_id: user.id,
      assigned_user_id: null,
      facility_id: user.role_id === 2 ? user.id : null,
      state_id: null,
      city_id: null,
      assigned_unit: null,
      start_date: null,
      end_date: null,
      assignment_length: null,
      shift: null,
      approved_time_off: null,
      confirmed_with_facility: 0,
      confirmed_with_traveler: 0,
      status: null,
    });
    setErrors({});
  };

  // Pass the function to the parent using a ref
  if (resetFormDataAndErrorsRef) {
    resetFormDataAndErrorsRef.current = resetFormDataAndErrors;
  }

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      setIsLoading(true);
      const result = await api.postFileMethod(formData, "client/update-assignment");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);

        //document.getElementById("file_name").value = "";
        toggleUploadModal(false);
        fetchComplianceFiles();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields(name, value);
  };

  const validateFields = (name, value) => {
    try {
      validationSchema.validateSyncAt(name, { [name]: value });

      // Clear the error for the current field if validation passes
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: "",
      }));
    } catch (error) {
      setErrors((prevErrors) => ({
        ...prevErrors,
        [name]: error.message,
      }));
    }
  };

  return (
    <>
      <Modal show={uploadModalOpen} onHide={toggleUploadModal} centered backdrop="static" size="lg">
        <form onSubmit={handleSubmit}>
          <Modal.Header closeButton>
            <Modal.Title className="my-0">Add Assignment</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <div className="row">
              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="assigned_user_id">
                  Candidate *
                </label>
                <select
                  className="form-control"
                  id="assigned_user_id"
                  name="assigned_user_id"
                  onChange={handleChange}
                  value={formData.assigned_user_id !== null ? formData.assigned_user_id : ""}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the states */}
                  {UserLists.map((user) => (
                    <option key={user.id} value={user.id}>
                      {user.name}
                    </option>
                  ))}
                </select>
                {errors.assigned_user_id && (
                  <span className="error">{errors.assigned_user_id}</span>
                )}
              </div>

              {user.role_id == 3 && (
                <div className="mb-2 col-md-4">
                  <label className="form-label" htmlFor="facility_id">
                    Facility Name *
                  </label>

                  <input
                    type="text"
                    className="form-control"
                    id="facility_id"
                    name="facility_id"
                    onChange={handleChange}
                    defaultValue={formData.facility_id !== null ? formData.facility_id : ""}
                  />

                  {/* 
                  <select
                    className="form-control"
                    id="facility_id"
                    name="facility_id"
                    onChange={handleChange}
                    value={formData.facility_id !== null ? formData.facility_id : ""}
                  >
                    <option value="" key="">
                      Please Select
                    </option>
                    {facilityLists.map((facility) => (
                      <option key={facility.id} value={facility.id}>
                        {facility.title}
                      </option>
                    ))}
                </select>
                */}
                  {errors.facility_id && <span className="error">{errors.facility_id}</span>}
                </div>
              )}

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="assigned_unit">
                  Assigned Unit
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="assigned_unit"
                  name="assigned_unit"
                  onChange={handleChange}
                  defaultValue={formData.assigned_unit !== null ? formData.assigned_unit : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="start_date">
                  Start Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="start_date"
                  name="start_date"
                  onChange={handleChange}
                  defaultValue={formData.start_date !== null ? formData.start_date : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="end_date">
                  End Date
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="end_date"
                  name="end_date"
                  onChange={handleChange}
                  defaultValue={formData.end_date !== null ? formData.end_date : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="assignment_length">
                  Assignment Length
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="assignment_length"
                  name="assignment_length"
                  onChange={handleChange}
                  defaultValue={
                    formData.assignment_length !== null ? formData.assignment_length : ""
                  }
                />
              </div>

              <div className="mb-2 col-md-4">
                <label htmlFor="state_id">State *</label>
                <select
                  id="state_id"
                  className="form-control"
                  name="state_id"
                  value={formData.state_id !== null ? formData.state_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {states.map((state) => (
                    <option key={state.id} value={state.id}>
                      {state.name}
                    </option>
                  ))}
                </select>
                {errors.state_id && <span className="error">{errors.state_id}</span>}
              </div>

              <div className="mb-2 col-md-4">
                <label className="form-label" htmlFor="city_id">
                  City *
                </label>
                <select
                  id="city_id"
                  className="form-control"
                  name="city_id"
                  value={formData.city_id !== null ? formData.city_id : ""}
                  onChange={handleChange}
                >
                  <option value="" key="">
                    Please Select
                  </option>
                  {/* Render the professions */}
                  {cities.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.city_name}
                    </option>
                  ))}
                </select>
                {errors.city_id && <span className="error">{errors.city_id}</span>}
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="shift">
                  Shift
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="shift"
                  name="shift"
                  onChange={handleChange}
                  defaultValue={formData.shift !== null ? formData.shift : ""}
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="approved_time_off">
                  Approved Time Off
                </label>
                <input
                  type="date"
                  className="form-control"
                  id="approved_time_off"
                  name="approved_time_off"
                  onChange={handleChange}
                  defaultValue={
                    formData.approved_time_off !== null ? formData.approved_time_off : ""
                  }
                />
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="confirmed_with_facility">
                  Confirmed with Facility
                </label>
                <select
                  className="form-control"
                  id="confirmed_with_facility"
                  name="confirmed_with_facility"
                  onChange={handleChange}
                  value={formData.confirmed_with_facility}
                >
                  <option key="1" value="1">
                    Yes
                  </option>
                  <option key="0" value="0">
                    No
                  </option>
                </select>
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="confirmed_with_traveler">
                  Confirmed with Traveler
                </label>
                <select
                  className="form-control"
                  id="confirmed_with_traveler"
                  name="confirmed_with_traveler"
                  onChange={handleChange}
                  value={formData.confirmed_with_traveler}
                >
                  <option key="1" value="1">
                    Yes
                  </option>
                  <option key="0" value="0">
                    No
                  </option>
                </select>
              </div>

              <div className="col-md-4 mb-2">
                <label className="form-label" htmlFor="fstatus">
                  Status
                </label>
                <select
                  className="form-control"
                  id="fstatus"
                  name="status"
                  onChange={handleChange}
                  value={formData.status !== null ? formData.status : "1"}
                >
                  <option key="1" value="1">
                    Active
                  </option>
                  <option key="0" value="0">
                    In-Active
                  </option>
                </select>
                {errors.cat_id && <span className="error">{errors.cat_id}</span>}
              </div>
            </div>
          </Modal.Body>
          <Modal.Footer>
            <button type="button" className="btn btn-secondary" onClick={toggleUploadModal}>
              Close
            </button>
            <button type="submit" className="btn btn-primary">
              Submit
            </button>
          </Modal.Footer>
        </form>
      </Modal>
    </>
  );
};

export default AssignmentModal;
