import React, { useState, useEffect } from "react";
import * as api from "../../../../../services/ApiService";
import * as tokenUtils from "../../../../../utils/tokenUtils";

function ProfessionalInfo({ profileInfo, formatDate }) {
  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [workHistories, setWorkHisstories] = useState({});
  const [loading, setLoading] = useState(true);
  const [noRecordsFound, setNoRecordsFound] = useState(false);

  const fetchWorkHistoryData = async () => {
    try {
      const param = {
        user_id: profileInfo.id,
        token: user.token,
      };
      const apiData = await api.postMethod(param, "user/get-work-history");
      //const apiDataObj = Object.setPrototypeOf(apiData, Object.prototype);

      /*
      const apiDataObj = apiData.reduce((acc, currentValue, index) => {
        acc[index] = currentValue;
        return acc;
      }, {});
      */

      setWorkHisstories(apiData.data);
      setLoading(false);
      // Check if data array is empty
      if (apiData.data.length === 0) {
        setNoRecordsFound(true);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchWorkHistoryData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  return (
    <>
      <div className="row">
        {loading ? (
          <div className="col-md-12 text-center">Loading...</div>
        ) : (
          <>
            {noRecordsFound ? (
              <div className="col-md-12 ">No records found</div>
            ) : (
              <>
                {Object.entries(workHistories).map(([key, value]) => (
                  <div className="col-md-6" key={value.id}>
                    <div className="bg-light p-3 mb-3">
                      <h4 className="mt-0">{value.title}</h4>
                      {value.company_name && (
                        <p className="text-muted text-uppercase">
                          <i className="fas fa-briefcase" /> <small>{value.company_name}</small>
                        </p>
                      )}
                      <div className="row">
                        <div className="col-md-4">
                          <div className="mb-0">
                            <h5>Location</h5>
                            <p className="mb-0">
                              {value.state_name !== null && value.city_name !== null
                                ? value.state_name + ", " + value.city_name
                                : value.state_name !== null
                                ? value.state_name
                                : value.city_name !== null
                                ? value.state_name
                                : "-"}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-0">
                            <h5>Start Date</h5>
                            <p className="mb-0">
                              {value.start_month}/{value.start_year}
                            </p>
                          </div>
                        </div>
                        <div className="col-md-4">
                          <div className="mb-0">
                            <h5>End Date</h5>
                            <p className="mb-0">
                              {value.currently_working === "1"
                                ? "Currently Working"
                                : value.end_month + "/" + value.end_year}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </>
            )}
          </>
        )}
      </div>
    </>
  );
}

export default ProfessionalInfo;
