import React, { useEffect, useState } from "react";
import { useParams, Link, useNavigate } from "react-router-dom";
import Header from "../../../Layout/Header";
import Footer from "../../../Layout/Footer";
import Sidebar from "../../../Layout/Sidebar";
import ThemeSettings from "../../../Layout/ThemeSettings";
import * as api from "../../../../services/ApiService";
import * as Yup from "yup";
import * as tokenUtils from "../../../../utils/tokenUtils";
import * as toast from "../../../../utils/toastUtils";
import PageTitle from "../../../Layout/PageTitle";
import { currentRole } from "../../../Layout/HelmetComponent";
import PasswordToggle from "../../../../hooks/PasswordToggle";
import { phoneRegExp } from "../../../../utils/dateFormat";

function AddCandidateStep1() {
  let navigate = useNavigate();

  const { userID } = useParams();

  // Get data from token util
  let user = tokenUtils.getTokenData();

  const [formData, setformData] = useState({
    step: "step1",
    id: null,
    user_id: user.id,
    token: user.token,
    role_id: 9,
    name: null,
    email: null,
    phone: null,
    address_line1: null,
    address_line2: null,
    state_id: null,
    city_id: null,
    password: null,
    confirm_password: null,
  });

  const [showPassword, togglePasswordVisibility] = PasswordToggle(formData, setformData);

  const fetchUserDetails = async () => {
    try {
      const param = {
        step: "step1",
        user_id: user.id,
        token: user.token,
        userID: userID,
      };
      const apiData = await api.postMethod(param, "admin/get-user-details");

      if (apiData.status) {
        // Save the data in local storage
        //localStorage.setItem("job_id", apiData.data.id);
        //navigate("/admin/job-post-step2");
        //setformData(apiData.data);

        setformData({
          ...formData,
          id: apiData.data.id,
          name: apiData.data.name,
          email: apiData.data.email,
          phone: apiData.data.phone,
          address_line1: apiData.data.address_line1,
          address_line2: apiData.data.address_line2,
          state_id: apiData.data.state_id,
          city_id: apiData.data.city_id,
        });
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  useEffect(() => {
    fetchUserDetails();
  }, [userID]); // Empty dependency array ensures that the effect runs only once, when the component mounts

  //Define the validation schema
  const validationSchema = Yup.object({
    name: Yup.string().required("Name is Required"),
    email: Yup.string().email("Invalid email format").required("Email is Required"),
    phone: Yup.string()
      .matches(phoneRegExp, "Phone number is not valid")
      .required("Phone number is Required"),
    state_id: Yup.string().required("State is Required"),
    city_id: Yup.string().required("City is Required"),
    password: userID ? Yup.string().notRequired() : Yup.string().required("Password is required"),
    confirm_password: userID
      ? Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .notRequired()
      : Yup.string()
          .oneOf([Yup.ref("password"), null], "Passwords must match")
          .required("Confirm Password is required"),

    /*
    password: Yup.string().when("userID", {
      is: null,
      then: Yup.string().required("Password is required"),
      otherwise: Yup.string().notRequired(),
    }),
    confirm_password: Yup.string().when(userID, {
      is: null,
      then: Yup.string()
        .oneOf([Yup.ref("password"), null], "Passwords must match")
        .required("Confirm Password is required"),
      otherwise: Yup.string().notRequired(),
    }),
    */
  });

  const [errors, setErrors] = useState({});

  // Handle input change
  const handleChange = (e) => {
    const { name, value } = e.target;
    setformData({ ...formData, [name]: value });

    validateFields({ ...formData, [name]: value });
  };

  // Validate the entire form data
  const validateFields = (data) => {
    try {
      validationSchema.validateSync(data, { abortEarly: false });

      // Clear all errors if validation passes
      setErrors({});
    } catch (validationErrors) {
      const formattedErrors = validationErrors.inner.reduce((acc, error) => {
        return { ...acc, [error.path]: error.message };
      }, {});

      setErrors(formattedErrors);
    }
  };

  const handleFormSubmit = async (e, navigateTo) => {
    e.preventDefault();

    try {
      await validationSchema.validate(formData, { abortEarly: false });

      const result = await api.postMethod(formData, "admin/add-candidate");
      if (result.status) {
        // Save the data in local storage
        localStorage.setItem("employee_id", result.data.id);
        localStorage.setItem("employee_unique_id", userID);
        //navigate("/admin/add-employee-step2");
        navigate(navigateTo);
        toast.success(result.message);
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      const newErrors = {};
      if (error.inner && Array.isArray(error.inner)) {
        error.inner.forEach((element) => {
          newErrors[element.path] = element.message;
        });
      } else {
        console.error("Unexpected error structure:", error);
      }
      setErrors(newErrors);
    }
  };

  const [states, setStates] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchData = async () => {
      try {
        const apiData = await api.getMethod("get-states");
        setStates(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchData();
  }, []); // Empty dependency array ensures that the effect runs only once, when the component mounts

  const [cities, setCities] = useState([]);

  useEffect(() => {
    // Function to fetch data from the API
    const fetchCityData = async () => {
      try {
        const apiData = await api.getMethod("get-cities/" + formData.state_id);

        setCities(apiData.data);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    // Call the fetchData function when the component mounts
    fetchCityData();
  }, [formData.state_id]); // Empty dependency array ensures that the effect runs only once, when the component mounts
  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Employees", link: "/admin/employees" },
    { text: "Add Employee", link: null },
  ];

  const handleRedirectSubmit = async (e, navigateTo) => {
    e.preventDefault();
    navigate(navigateTo);
  };

  return (
    <>
      <div id="wrapper">
        <Sidebar />

        <div className="content-page">
          <Header />

          <div className="content">
            {/* Start Content*/}
            <div className="container-fluid">
              {/* start page title */}
              <PageTitle title="Add Employee" breadcrumbItems={breadcrumbItems} />

              {/*Job progress bar start*/}
              <div className="row">
                <div className="col-12">
                  <div className="stepper-wrapper">
                    <div className="stepper-item active">
                      <div className="step-counter">1</div>
                      <div className="step-name">Personal Information</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">2</div>
                      <div className="step-name">Job Preferences</div>
                    </div>
                    {/* 
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Professional Details</div>
                    </div>
                    */}
                    <div className="stepper-item">
                      <div className="step-counter">3</div>
                      <div className="step-name">Work History</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">4</div>
                      <div className="step-name">Educational Information</div>
                    </div>
                    <div className="stepper-item">
                      <div className="step-counter">5</div>
                      <div className="step-name">Skills</div>
                    </div>
                  </div>
                </div>
              </div>
              {/*Job progress bar end*/}
              <div className="row">
                <div className="col-12">
                  <div className="card custom-box-shadow">
                    <div className="card-body">
                      <form onSubmit={(e) => handleFormSubmit(e, "/admin/add-employee-step2")}>
                        <div className="row">
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="name">Name</label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              name="name"
                              defaultValue={formData.name}
                              onChange={handleChange}
                            />
                            {errors.name && <span className="error">{errors.name}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="email">Email</label>
                            <input
                              type="email"
                              className="form-control"
                              id="email"
                              name="email"
                              defaultValue={formData.email != null ? formData.email : ""}
                              onChange={handleChange}
                            />
                            {errors.email && <span className="error">{errors.email}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-4">
                            <label htmlFor="phone">Phone Number</label>
                            <div className="input-group">
                              <div className="input-group-prepend">
                                <span className="input-group-text">+1</span>
                              </div>
                              <input
                                type="text"
                                className="form-control"
                                id="phone"
                                name="phone"
                                defaultValue={formData.phone != null ? formData.phone : ""}
                                onChange={handleChange}
                              />
                            </div>
                            {errors.phone && <span className="error">{errors.phone}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="address_line1">Address Line 1 </label>
                            <input
                              type="text"
                              className="form-control"
                              id="address_line1"
                              name="address_line1"
                              defaultValue={
                                formData.address_line1 != null ? formData.address_line1 : ""
                              }
                              onChange={handleChange}
                            />
                          </div>

                          <div className="form-group mb-3 col-md-6">
                            <label htmlFor="address_line2">Address Line 2 </label>
                            <input
                              type="text"
                              className="form-control"
                              id="address_line2"
                              name="address_line2"
                              defaultValue={
                                formData.address_line2 != null ? formData.address_line2 : ""
                              }
                              onChange={handleChange}
                            />
                          </div>
                        </div>
                        <div className="row">
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="state_id">State *</label>
                            <select
                              id="state_id"
                              className="form-control"
                              name="state_id"
                              value={formData.state_id !== null ? formData.state_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {states.map((state) => (
                                <option key={state.id} value={state.id}>
                                  {state.name}
                                </option>
                              ))}
                            </select>
                            {errors.state_id && <span className="error">{errors.state_id}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="city_id">City *</label>
                            <select
                              id="city_id"
                              className="form-control"
                              name="city_id"
                              value={formData.city_id !== null ? formData.city_id : ""}
                              onChange={handleChange}
                            >
                              <option value="" key="">
                                Please Select
                              </option>
                              {/* Render the professions */}
                              {cities.map((city) => (
                                <option key={city.id} value={city.id}>
                                  {city.city_name}
                                </option>
                              ))}
                            </select>
                            {errors.city_id && <span className="error">{errors.city_id}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="password">Password </label>
                            <div className="input-group input-group-merge">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="password"
                                name="password"
                                value={formData.password}
                                onChange={handleChange}
                              />
                              <div
                                className={
                                  showPassword
                                    ? "show-password input-group-append"
                                    : "input-group-append"
                                }
                                data-password="false"
                              >
                                <div className="input-group-text">
                                  <span
                                    className="password-eye"
                                    onClick={togglePasswordVisibility}
                                  />
                                </div>
                              </div>
                            </div>
                            {errors.password && <span className="error">{errors.password}</span>}
                          </div>
                          <div className="form-group mb-3 col-md-3">
                            <label htmlFor="confirm_password">Confirm Password </label>
                            <div className="input-group input-group-merge">
                              <input
                                type={showPassword ? "text" : "password"}
                                className="form-control"
                                id="confirm_password"
                                name="confirm_password"
                                value={formData.confirm_password}
                                onChange={handleChange}
                              />
                              <div
                                className={
                                  showPassword
                                    ? "show-password input-group-append"
                                    : "input-group-append"
                                }
                                data-password="false"
                              >
                                <div className="input-group-text">
                                  <span
                                    className="password-eye"
                                    onClick={togglePasswordVisibility}
                                  />
                                </div>
                              </div>
                            </div>
                            {errors.confirm_password && (
                              <span className="error">{errors.confirm_password}</span>
                            )}
                          </div>
                        </div>
                        <div className="row">
                          <div className="col-md-12">
                            <div className="text-lg-end">
                              <button type="submit" className="btn btn-primary">
                                Save and Continue <i className="fas fa-angle-right" />
                              </button>

                              <button
                                className="btn btn-info ms-1 float-end ms-1"
                                type="button"
                                onClick={(e) => handleFormSubmit(e, "/admin/employees")}
                              >
                                Save & Exit <i className="fas fa-check" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </form>
                    </div>
                    {/* end card-body*/}
                  </div>
                  {/* end card*/}
                </div>
                {/* end col */}
              </div>
              {/* end row */}
            </div>
            {/* container */}
          </div>

          <Footer />
        </div>
      </div>
      <ThemeSettings />
    </>
  );
}

export default AddCandidateStep1;
