import React, { useEffect, useState } from "react";
import Layout from "../../Layout/Index";
import { getTokenData } from "../../../utils/tokenUtils";
import * as api from "../../../services/ApiService";
import Spinner from "../../Loader/Spinner";
import { toast } from "react-toastify";
import { formatDate } from "../../../utils/dateFormat";
import { useFormik } from "formik";
import * as Yup from "yup";
import Modal from "react-bootstrap/Modal";
import PageTitle from "../../Layout/PageTitle";
import { Tooltip } from "react-tooltip";
import useConfirm from "../../../hooks/useConfirm";

const Professions = () => {
  let user = getTokenData();

  /* Filters function Start */
  const [showFilterDropdown, setFilterDropdown] = useState(false);
  const handleToggleFilterDropDown = () => {
    setFilterDropdown(!showFilterDropdown);
  };

  // State for filter values
  const [filters, setFilters] = useState({
    keyword: "",
    status: "all",
  });

  // Handle form reset
  const handleReset = () => {
    const defaultFilters = {
      keyword: "",
      status: "all",
    };
    setFilters(defaultFilters);
    setFilterDropdown(false);
  };

  useEffect(() => {
    if (showFilterDropdown === false) {
      fetchProfessions();
    }
  }, [showFilterDropdown]);

  // Handle form input change
  const handleFilterInputChange = (e) => {
    const { name, value, type, checked } = e.target;

    if (type === "checkbox") {
      setFilters({
        ...filters,
        [name]: checked,
      });
    } else {
      setFilters({
        ...filters,
        [name]: value,
      });
    }
  };

  // Handle form submit
  const handleFilterSubmit = (e) => {
    e.preventDefault();
    setFilterDropdown(false);
  };
  /* Filters function End */

  const [isLoading, setIsLoading] = useState(false);
  const [professionData, setProfessionData] = useState([]);
  const [loading, setLoading] = useState(true);
  const [editId, setEditId] = useState(null);
  const [showModal, setShowModal] = useState(false);

  const fetchProfessions = async () => {
    try {
      const param = {
        user_id: user.id,
        token: user.token,
      };
      const queryParams = { ...param, ...filters };
      const apiData = await api.postMethod(queryParams, "admin/get-professions");

      setProfessionData(apiData.data);
      setLoading(false);
    } catch (error) {
      console.error("Error fetching data:", error);
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchProfessions();
  }, []);

  // validation with formik
  const formik = useFormik({
    initialValues: {
      title: "",
      parentProfession: "",
    },
    validationSchema: Yup.object({
      title: Yup.string().required("Title is required"),
      parentProfession: Yup.string().notRequired(),
    }),
    onSubmit: async (values, { resetForm }) => {
      setIsLoading(true);
      try {
        const params = {
          profession: values.title,
          parent_id: values.parentProfession ? values.parentProfession : 0,
          user_id: user.id,
        };
        if (editId) {
          params.id = editId;
        }
        let result = await api.postMethod(params, "admin/update-professions");
        if (result.status) {
          fetchProfessions();
          resetForm();
          setEditId(null);
          setShowModal(false);
          toast.success(result.message);
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        console.error("Error submitting data:", error);
      } finally {
        setIsLoading(false);
      }
    },
  });

  const handleEdit = (profession) => {
    const parentProfessionId =
      professionData.find((p) => p.profession === profession.parent_profession)?.id || 0;
    formik.setValues({
      title: profession.profession,
      parentProfession: parentProfessionId,
    });
    setEditId(profession.id);
    setShowModal(true); // Show the modal
  };

  const { confirm } = useConfirm();
  const handleDelete = async (profession) => {
    const confirmed = await confirm();
    if (confirmed.isConfirmed) {
      profession.user_id = user.id;
      setIsLoading(true);
      const result = await api.postMethod(profession, "admin/delete-profession");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchProfessions();
      }
    }
  };

  /* Select All checkbox functions start */

  const [selectedUserIds, setSelectedUserIds] = useState([]);
  const [bulkAction, setBulkAction] = useState("");

  const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allUserIds = professionData.map((record) => record.id);
      setSelectedUserIds(allUserIds);
    } else {
      setSelectedUserIds([]);
    }
  };

  const handleCheckboxChange = (event) => {
    const userId = parseInt(event.target.value);
    setSelectedUserIds((prevSelectedUserIds) => {
      if (event.target.checked) {
        // Add the user ID to the list
        return [...prevSelectedUserIds, userId];
      } else {
        // Remove the user ID from the list
        return prevSelectedUserIds.filter((id) => id !== userId);
      }
    });
  };

  const handleBulkActionChange = (event) => {
    setBulkAction(event.target.value);
  };

  const handleBulkActionSubmit = async () => {
    if (bulkAction === "") {
      toast.error("Please any action from bulk actions");
    } else if (selectedUserIds.length === 0) {
      toast.error("Please select atleast 1 record to perform bulk action");
    } else {
      try {
        setIsLoading(true);
        const param = {
          user_id: user.id,
          user_ids: selectedUserIds,
          bulk_action: bulkAction,
        };
        const result = await api.postFileMethod(param, "admin/profession-bulk-actions");
        setIsLoading(false);
        if (result.status) {
          toast.success(result.message);
          setSelectedUserIds([]);
          setBulkAction("");
          fetchProfessions();
        } else {
          toast.error(result.message);
        }
      } catch (error) {
        setIsLoading(false);
      }
    }
  };

  const updateStatus = async (id, status) => {
    try {
      setIsLoading(true);
      const param = {
        user_id: user.id,
        id: id,
        status: status,
      };
      const result = await api.postFileMethod(param, "admin/update-profession-status");
      setIsLoading(false);
      if (result.status) {
        toast.success(result.message);
        fetchProfessions();
      } else {
        toast.error(result.message);
      }
    } catch (error) {
      setIsLoading(false);
    }
  };

  const breadcrumbItems = [
    { text: "Admin Dashboard", link: "/admin/dashboard" },
    { text: "Professions", link: null },
  ];

  return (
    <Layout>
      <PageTitle title="Professions" breadcrumbItems={breadcrumbItems} />

      {/* end page title */}
      <div className="row">
        <div className="col-12">
          <div className="card custom-box-shadow">
            <div className="card-body">
              <div className="row justify-content-between">
                <div className="col-md-6">
                  <div className="">
                    <select
                      className="form-select my-1 my-lg-0 w-auto d-inline me-1"
                      value={bulkAction}
                      onChange={handleBulkActionChange}
                    >
                      <option value="">Bulk Actions</option>
                      <option value="delete">Delete</option>
                      <option value="change-status-active">Change Status to Active</option>
                      <option value="change-status-inactive">Change Status to In-Active</option>
                    </select>
                    <button onClick={handleBulkActionSubmit} className="btn btn-info">
                      Submit
                    </button>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="dropdown" style={{ width: 300 }}>
                    <button
                      type="button"
                      className="btn btn-outline-light dropdown-toggle w-100 text-start"
                      onClick={handleToggleFilterDropDown}
                    >
                      Filters
                      <i className="mdi mdi-chevron-down float-end" />
                    </button>
                    <div className={`dropdown-menu ${showFilterDropdown ? "show" : ""}`}>
                      <form className="px-2 py-2" onSubmit={handleFilterSubmit}>
                        <div className="mb-2">
                          <label htmlFor="keyword" className="form-label">
                            Keyword
                          </label>
                          <input
                            type="text"
                            className="form-control"
                            id="keyword"
                            name="keyword"
                            value={filters.keyword}
                            onChange={handleFilterInputChange}
                          />
                        </div>

                        <div className="mb-2">
                          <label htmlFor="status" className="form-label">
                            Status
                          </label>
                          <select
                            className="form-select"
                            id="cstatus"
                            name="status"
                            value={filters.status}
                            onChange={handleFilterInputChange}
                          >
                            <option value={"all"}>Please Select</option>
                            <option value={1}>Active</option>
                            <option value={0}>In-Active</option>
                          </select>
                        </div>

                        <div className="text-end mt-1">
                          <button
                            type="button"
                            className="btn btn-light float-start"
                            onClick={handleReset}
                          >
                            Reset
                          </button>
                          <button type="submit" className="btn btn-primary">
                            Submit
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-auto">
                  <div className="text-lg-end my-1 my-lg-0">
                    <button
                      type="button"
                      className="btn btn-primary waves-effect waves-light mb-2"
                      onClick={() => setShowModal(true)}
                    >
                      <i className="fa fa-plus mr-1" /> Add New Profession
                    </button>
                  </div>
                </div>
                {/* end col*/}
              </div>
              {/* Add Employee Modal */}
              <Modal show={showModal} onHide={() => setShowModal(false)} centered>
                <form onSubmit={formik.handleSubmit}>
                  <Modal.Header closeButton className="py-1">
                    <Modal.Title>{editId ? "Edit Profession" : "Add Profession"}</Modal.Title>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="form-group mb-3">
                      <label htmlFor="parentProfession" className="form-label">
                        Parent Profession
                      </label>
                      <select
                        id="parentProfession"
                        className="form-control"
                        value={formik.values.parentProfession}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      >
                        <option value="">Please select</option>
                        {professionData.map((profession) => (
                          <option key={profession.id} value={profession.id}>
                            {profession.profession}
                          </option>
                        ))}
                      </select>
                      {formik.touched.parentProfession && formik.errors.parentProfession ? (
                        <div className="error">{formik.errors.parentProfession}</div>
                      ) : null}
                    </div>
                    <div className="form-group mb-0">
                      <label htmlFor="title" className="form-label">
                        Title *
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="title"
                        placeholder="Title"
                        value={formik.values.title}
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                      />
                      {formik.touched.title && formik.errors.title ? (
                        <div className="error">{formik.errors.title}</div>
                      ) : null}
                    </div>
                  </Modal.Body>
                  <Modal.Footer>
                    <button
                      type="button"
                      className="btn btn-secondary waves-effect"
                      onClick={() => setShowModal(false)}
                    >
                      Close
                    </button>
                    <button
                      type="submit"
                      className="btn btn-success waves-effect waves-light"
                      disabled={isLoading}
                    >
                      Submit <i className="fa fa-check" />
                    </button>
                  </Modal.Footer>
                </form>
              </Modal>

              {/* /.modal */}
              <div className="table-responsive">
                <table className="table table-centered table-nowrap mb-0">
                  <thead className="table-light">
                    <tr>
                      <th className="form-checks">
                        <input
                          type="checkbox"
                          onChange={handleSelectAllChange}
                          checked={
                            selectedUserIds.length === professionData.length &&
                            professionData.length > 0
                          }
                          className="form-check-input"
                        />
                      </th>
                      <th>Title</th>
                      <th>Parent Profession</th>
                      <th>Creation Date</th>
                      <th>Status</th>
                      <th>Action</th>
                    </tr>
                  </thead>
                  <tbody>
                    {loading ? (
                      <tr>
                        <td colSpan="6">Loading...</td>
                      </tr>
                    ) : professionData.length === 0 ? (
                      <tr>
                        <td colSpan="6">No records found</td>
                      </tr>
                    ) : (
                      professionData.map((profession) => (
                        <tr key={profession.id}>
                          <td className="form-checks">
                            <input
                              type="checkbox"
                              value={profession.id}
                              name="user_ids"
                              onChange={handleCheckboxChange}
                              checked={selectedUserIds.includes(profession.id)}
                              className="form-check-input"
                            />
                          </td>
                          <td>{profession.profession}</td>
                          <td>{profession.parent_profession || "-"}</td>
                          <td>
                            {formatDate(profession.created_at, "MMM DD, YYYY")}{" "}
                            <small className="text-muted">
                              {formatDate(profession.created_at, "hh:mm A")}
                            </small>
                          </td>
                          <td>
                            {profession.status == 1 && (
                              <label className="badge badge-soft-success">Active</label>
                            )}
                            {profession.status == 0 && (
                              <label className="badge badge-soft-warning">In-Active</label>
                            )}
                          </td>
                          <td>
                            {profession.status == 0 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-default action-icon"
                                  data-tooltip-id="mt-1"
                                  data-tooltip-content="In-Active"
                                  onClick={() => updateStatus(profession.id, 1)}
                                >
                                  <i className="mdi mdi-close-box-outline" />
                                </button>
                                <Tooltip id="mt-1"></Tooltip>
                              </>
                            )}
                            {profession.status == 1 && (
                              <>
                                <button
                                  type="button"
                                  className="btn btn-default action-icon"
                                  data-tooltip-id="mt-1"
                                  data-tooltip-content="Active"
                                  onClick={() => updateStatus(profession.id, 0)}
                                >
                                  <i className="mdi mdi-check-box-outline" />
                                </button>
                                <Tooltip id="mt-1"></Tooltip>
                              </>
                            )}

                            <button
                              type="button"
                              className="btn btn-default action-icon"
                              onClick={() => handleEdit(profession)}
                              data-tooltip-id="mt-2"
                              data-tooltip-content="Edit Profession"
                            >
                              <i className="mdi mdi-pencil" />
                            </button>
                            <Tooltip id="mt-2"></Tooltip>

                            <button
                              type="button"
                              className="btn btn-default action-icon"
                              onClick={() => handleDelete(profession)}
                              data-tooltip-id="mt-2"
                              data-tooltip-content="Remove Profession"
                            >
                              <i className="mdi mdi-delete" />
                            </button>
                            <Tooltip id="mt-3"></Tooltip>
                          </td>
                        </tr>
                      ))
                    )}
                  </tbody>
                </table>
              </div>
            </div>
            {/* end card-body*/}
          </div>
          {/* end card*/}
        </div>
        {/* end col */}
      </div>

      {isLoading === true && <Spinner />}
    </Layout>
  );
};

export default Professions;
